import { ENVIRONMENT_GLOBAL } from './global';
//
// Set environment variables specific for all qualification servers to share
//
export const ENVIRONMENT_QUAL = {
  ...ENVIRONMENT_GLOBAL,
  auth0: {
    audience: 'https://onemedical.com',
    clientId: 'lXSBxDw7VYlmhgJyFJZ9xeMeOcCpiiUv',
    domain: 'qual-onemedical.auth0.com',
  },
  appSync: {
    apiUrl: 'https://g5cmgzifxvdvrgxu6hyuylbsom.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    enabled: true,
  },
  designSystemUrl: '/design/index.html',
  devRoutes: true,
  enableProdMode: true,
  gaAccount: 'UA-39757505-1',
  marketingSite: 'https://mktg-stage.onemedical.com',
  mixpanelDebug: true,
  mixpanelToken: '44008e1ef10bb5ecc7b4f967257c824d',
  pusherKey: '5422148b626236f6bff3',
  recaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // test recaptcha key
  rollbarAccessToken: '5f05e29063a14ec28e3f6f6c3062cc8c',
  stripePublishableKey: 'pk_test_n8l9UNf6ai3uDaNVAiuWp8Tu',
  topLevelDomain: 'app.1life.com',
};
