export enum StepName {
  accountSetUp = 'accountSetUp',
  activationCode = 'activationCode',
  createAccount = 'createAccount',
  dobSex = 'dobSex',
  emailVerification = 'emailVerification',
  employeeId = 'employeeId',
  membershipSelection = 'membershipSelection',
  serviceArea = 'serviceArea',
  termsOfService = 'termsOfService',
  workEmail = 'workEmail',
  success = 'success',
  directSignup = 'directSignup',
  adultDirectSignup = 'adultDirectSignupConfig',
  pediatricDirectSignupSuccess = 'pediatricDirectSignupSuccess',
  verifiedAccountSetUp = 'verifiedAccountSetUp',
}
