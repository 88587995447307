<label class="container">
  <input
    *ngIf="ngrxFormControlState"
    type="radio"
    value="{{ this.value }}"
    [ngrxFormControlState]="ngrxFormControlState"
    [disabled]="disabled"
  />
  <input
    *ngIf="!ngrxFormControlState"
    (change)="handleSelect()"
    type="radio"
    value="{{ this.value }}"
    name="{{ name }}"
    [checked]="checked"
    [disabled]="disabled"
  />
  {{ this.label }}
  <ng-content></ng-content>
  <span class="custom-radio"></span>
</label>
