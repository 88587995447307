import { Component, Input } from '@angular/core';

export enum OmguiBadgeVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
  WARNING = 'warning',
  PROMO = 'promo',
}

@Component({
  selector: 'omgui-badge',
  templateUrl: './omgui-badge.component.html',
  styleUrls: ['./omgui-badge.component.scss'],
})
export class OmguiBadgeComponent {
  @Input() text: string;
  @Input() variant: OmguiBadgeVariant = OmguiBadgeVariant.PRIMARY;
}
