export interface ApiV2AppointmentType {
  id: number;
  description: string;
  displayed_duration: number;
  display_name: string;
  first_appointment_selected: boolean;
  first_visit_appointment_type_id: number;
  is_specialty: boolean;
  long_description: string;
  name: string;
  position: number;
  remote: boolean;
}

export class AppointmentType {
  get id(): number {
    return this.firstAppointmentSelected ? this.firstVisitAppointmentTypeId : this._id;
  }

  constructor(id: number) {
    this._id = id;
  }

  description: string;
  displayedDuration: number;
  displayName: string;
  firstAppointmentSelected: boolean;
  firstVisitAppointmentTypeId: number;
  isSpecialty: boolean;
  longDescription: string;
  name: string;
  position: number;
  remote: boolean;
  requiresHirAuthorization: boolean;

  hasAdditionalQuestion = false;
  selected = false;

  private readonly _id: number;

  static fromApiV2({
    id,
    description,
    displayed_duration,
    display_name,
    first_appointment_selected,
    first_visit_appointment_type_id,
    is_specialty,
    long_description,
    name,
    position,
    remote,
  }: ApiV2AppointmentType): AppointmentType {
    const appointmentType = new AppointmentType(id);
    appointmentType.description = description;
    appointmentType.displayedDuration = displayed_duration;
    appointmentType.displayName = display_name;
    appointmentType.firstAppointmentSelected = first_appointment_selected;
    appointmentType.firstVisitAppointmentTypeId = first_visit_appointment_type_id;
    appointmentType.isSpecialty = is_specialty;
    appointmentType.longDescription = long_description;
    appointmentType.name = name;
    appointmentType.position = position;
    appointmentType.remote = remote;
    appointmentType.hasAdditionalQuestion = first_visit_appointment_type_id != null;

    return appointmentType;
  }

  static fromGraphQL({
    id,
    description,
    displayedDuration,
    displayName,
    firstVisitAppointmentType,
    isSpecialty,
    longDescription,
    name,
    position,
    remote,
    requiresHirAuthorization,
  }: any): AppointmentType {
    const appointmentType = new AppointmentType(+id);
    appointmentType.description = description;
    appointmentType.displayedDuration = displayedDuration;
    appointmentType.displayName = displayName;
    appointmentType.isSpecialty = isSpecialty;
    appointmentType.longDescription = longDescription;
    appointmentType.name = name;
    appointmentType.position = position;
    appointmentType.remote = remote;
    appointmentType.requiresHirAuthorization = requiresHirAuthorization;
    appointmentType.hasAdditionalQuestion = firstVisitAppointmentType?.id != null;

    if (firstVisitAppointmentType) {
      appointmentType.firstVisitAppointmentTypeId = +firstVisitAppointmentType.id;
    }

    return appointmentType;
  }

  toApiV2(): ApiV2AppointmentType {
    return {
      id: this._id,
      description: this.description,
      displayed_duration: this.displayedDuration,
      display_name: this.displayName,
      first_appointment_selected: this.firstAppointmentSelected,
      first_visit_appointment_type_id: this.firstVisitAppointmentTypeId,
      is_specialty: this.isSpecialty,
      long_description: this.longDescription,
      name: this.name,
      position: this.position,
      remote: this.remote,
    };
  }
}

/** @deprecated Added only because there might be some funky code that expects an AppointmentType instance despite
 * having no data to build it with.
 * */
export class BlankAppointmentType extends AppointmentType {
  constructor() {
    super(undefined);
  }
}
