import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { zip as zipObservables } from 'rxjs';

import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';

import { CreateHealthInformationReleaseAuthorizationGraphqlService } from './create-health-information-release-authorization-graphql.service';

@Injectable()
export class HealthInformationReleaseService {
  DOCUMENT_VERSION = '1.3';

  constructor(
    private createAuthorizationGraphQL: CreateHealthInformationReleaseAuthorizationGraphqlService,
    private membershipService: MembershipService,
    private userService: UserService,
  ) {}

  getUserAndMembership() {
    const { user$ } = this.userService;
    const membership$ = this.membershipService.getMembership();
    return zipObservables(user$, membership$);
  }

  createAuthorization(form: FormGroup) {
    return this.createAuthorizationGraphQL.mutate(this.authorizationParams(form));
  }

  private authorizationParams(form: FormGroup) {
    const { recipientTelephone, signatureText, signedAt, expiresAt } = form.value;
    return {
      recipientTelephone,
      signatureText,
      signedAt: signedAt.date,
      expiresAt: this.expiresAt(expiresAt),
      documentVersion: this.DOCUMENT_VERSION,
    };
  }

  private expiresAt(expiresAt): string {
    if (expiresAt === '' || expiresAt.date === '' || expiresAt.date === null) {
      return moment().add(2, 'y').format('YYYY-MM-DD');
    }
    return expiresAt.date;
  }

  private address(consenterAddress): string {
    let address = consenterAddress.address1;
    if (consenterAddress.address2) {
      address = `${address}, ${consenterAddress.address2}`;
    }
    return address;
  }
}
