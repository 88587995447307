import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { dateValidator } from '@app/shared/date-validator.directive';

@Component({
  selector: 'om-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['../form-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },

    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy, AfterViewInit {
  private destroy$ = new Subject();

  dateForm: FormGroup;
  monthMaxLength = 2;
  dayMaxLength = 2;
  // yearMaxLength is not defined since yearInput doesn't call #shiftInputFocusOnLimit (see #onYearChanged)

  @Input() required: Boolean = true;
  @Input() readOnly: Boolean = false;
  @ViewChild('monthInput', { static: true }) monthInput: ElementRef;
  @ViewChild('dayInput', { static: true }) dayInput: ElementRef;
  @ViewChild('yearInput', { static: true }) yearInput: ElementRef;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {}

  createForm() {
    this.dateForm = this.formBuilder.group({
      date: [null, dateValidator],
      month: [
        null,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.maxLength(2),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      day: [
        null,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.maxLength(2),
          Validators.pattern('^[0-9]*$'),
          this.validateDayOfMonth.bind(this),
        ],
      ],
      year: this.formBuilder.control(null, {
        validators: [
          Validators.required,
          Validators.min(1900),
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern('^[0-9]*$'),
        ],
        updateOn: 'change',
      }),
    });

    this.dateForm.controls.month.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => this.onMonthChanged(data));
    this.dateForm.controls.day.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onDayChanged(data));
    this.dateForm.controls.year.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.onYearChanged());
  }

  buildDate(formValue: { month: number; day: number; year: number }) {
    const { month, day, year } = formValue;
    let date;
    if ([month, day, year].join('') === '' && !this.required) {
      date = null;
      this.markAsPristineAndUntouched();
    } else {
      const value = moment([year, month - 1, day]);
      date = value.isValid() ? value.format('YYYY-MM-DD') : '';
      this.dateForm.controls.date.markAsDirty();
    }
    this.dateForm.controls.date.setValue(date, { emitEvent: false });
  }

  markAsTouchedAndDirty(): void {
    ['day', 'month', 'year', 'date'].forEach(input => {
      this.dateForm.controls[input].markAsTouched();
      this.dateForm.controls[input].markAsDirty();
    });
  }

  markAsPristineAndUntouched(): void {
    ['day', 'month', 'year', 'date'].forEach(input => {
      this.dateForm.controls[input].markAsPristine();
      this.dateForm.controls[input].markAsUntouched();
    });
  }

  onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val && val.date) {
      const date = moment(val.date, 'YYYY-MM-DD');
      const formState = {
        date: val.date,
        month: date.month() + 1,
        day: date.date(),
        year: date.year(),
      };

      this.dateForm.setValue(formState, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.dateForm.valueChanges.subscribe(data => {
      this.buildDate(data);
      fn(this.dateForm.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.dateForm.valid || (!this.required && this.emptyDate())
      ? null
      : { dateForm: { valid: false, message: 'date fields are invalid' } };
  }

  private emptyDate() {
    const { date } = this.dateForm.value;
    return date === null || date === undefined;
  }

  private onYearChanged() {
    this.dateForm.get('day').updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private onMonthChanged(data: string) {
    const monthsInYearLimiter = 1;
    this.shiftInputFocusOnLimit(data, monthsInYearLimiter, this.monthMaxLength, this.dayInput);
    this.dateForm.get('day').updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  private onDayChanged(data: string) {
    const daysInMonthLimiter = 3;
    this.shiftInputFocusOnLimit(data, daysInMonthLimiter, this.dayMaxLength, this.yearInput);
  }

  /**
   * Checks input value to see if it is 'complete' enough to move on to the next input
   *   e.g. By limit: Month values cannot start with a '2', so if the user enters digit >= 2
   *   e.g. By length: If a user types 2 digits as a day input ('01', '29')
   *
   * @param rawValue  Raw value from the input
   * @param limit     A value to check against 'data' to see if it's safe to focus the next input box
   * @param maxLength The max length of the raw input
   * @param nextInput The next element
   */
  private shiftInputFocusOnLimit(rawValue: string, limit: number, maxLength: number, nextInput: ElementRef) {
    const inputValue = parseInt(rawValue, 10);
    const maxLengthMet = rawValue.length === maxLength;
    const valueLimitMet = inputValue > limit;

    if (!isNaN(inputValue) && (maxLengthMet || valueLimitMet) && nextInput) {
      nextInput.nativeElement.focus();
    }
  }

  get valid() {
    return this.dateForm.valid;
  }

  private validateDayOfMonth(control: AbstractControl): ValidationErrors | null {
    if (!this.dateForm) {
      return;
    }

    const year: string = this.dateForm.get('year').value;
    const month: string = this.dateForm.get('month').value;
    const day: string = control.value;
    if (!year || !month || !day) {
      return;
    }

    const date = moment(`${year}-${month}`, 'YYYY-MM');
    const dayValue = Number.parseInt(day, 10);
    const daysInMonth = date.daysInMonth();

    return dayValue > daysInMonth ? { max: { max: daysInMonth, actual: dayValue } } : null;
  }
}
