/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { ApolloClient, ApolloLink, gql, InMemoryCache } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { ConfigService } from '@app/core/config.service';

const notificationSubscription = gql`
  subscription Notifications($resource: String!, $action: Action!, $scope: String!) {
    notification(resource: $resource, action: $action, scope: $scope) {
      payload
    }
  }
`;

@Injectable()
export class AppSyncNotificationService {
  private client: ApolloClient<any>;
  private apolloConfiguration: ApolloLink[];
  private readonly appSyncConfig;
  private _notifications = new Subject();
  private subscription: ZenObservable.Subscription;

  notifications$ = this._notifications.asObservable().pipe(filter(payload => payload != null));

  constructor(private configService: ConfigService, private authService: AuthService) {
    this.appSyncConfig = this.configService.json.appSync;
    if (!this.appSyncConfig.enabled) {
      return;
    }

    this.apolloConfiguration = [
      createAuthLink({
        url: this.appSyncConfig.apiUrl,
        region: this.appSyncConfig.region,
        auth: {
          type: 'OPENID_CONNECT',
          jwtToken: async () =>
            await new Promise(resolve => {
              this.authService.getToken().subscribe(token => resolve(token));
            }),
        },
      }),
      createSubscriptionHandshakeLink(this.appSyncConfig.apiUrl),
    ];
    this.initialize();
  }

  notificationSubscription(resource: string, action: string, scope: string) {
    if (!this.appSyncConfig.enabled || !this.client) {
      return;
    }

    this.subscription = this.client
      .subscribe({
        query: notificationSubscription,
        variables: {
          resource,
          action,
          scope,
        },
      })
      .subscribe(
        response => {
          const notification = JSON.parse(response?.data?.notification?.payload);
          if (notification) {
            this._notifications.next(notification);
          }
        },
        error => {
          this._notifications.next(error);
        },
      );
  }

  private initialize(configuration = this.apolloConfiguration) {
    this.client = new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from(configuration),
    });
  }
}
