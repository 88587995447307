import { MapsAPILoader } from '@agm/core';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { Address, BasicAddress } from '@app/shared/address';

@Component({
  selector: 'om-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['../../form-input.scss'],
})
export class AddressAutocompleteComponent implements OnInit, OnDestroy {
  @ViewChild('addressSearch', { static: true }) searchElementRef: ElementRef;
  @ViewChild('placesService', { static: true }) placesElementRef: ElementRef;

  @Input() hasError = false;
  @Input() existingAddress = '';
  @Input() enableAutoFocus = true;
  @Input() labelClass: string;
  @Input() label = 'Address';
  @Input() placeholder = 'Enter a location';
  @Input() prefilledRegistration: Boolean = false;

  addressQuery: FormControl;
  place$: Subject<google.maps.places.PlaceResult> = new Subject();

  @Output() addressSelected: EventEmitter<BasicAddress> = new EventEmitter<BasicAddress>();
  @Output() inputTouched: EventEmitter<null> = new EventEmitter<null>();

  hasEnteredAddress = false;

  private destroy$ = new Subject();
  autoComplete: google.maps.places.Autocomplete;
  autoCompleteService: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
  placeChangedListener: google.maps.MapsEventListener;

  hasPrediction = false;

  constructor(private mapsAPILoader: MapsAPILoader, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    if (this.placeChangedListener) {
      this.placeChangedListener.remove();
    }
    this.clearMapsServices();
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearMapsServices() {
    this.autoComplete = null;
    this.autoCompleteService = null;
    this.placesService = null;
  }

  ngOnInit() {
    this.addressQuery = new FormControl(this.existingAddress, Validators.required);
    this.setupAddressClearer();
    this.setupAddressParser();
    this.loadMapServices().then(() => this.setupMapListener());
    if (!!this.existingAddress && this.prefilledRegistration) {
      this.hasEnteredAddress = true;
      this.predictAddress();
    }
  }

  loadMapServices() {
    return this.mapsAPILoader.load();
  }

  setupAddressClearer() {
    this.addressQuery.valueChanges.subscribe(() => {
      if (this.hasEnteredAddress && this.hasPrediction) {
        this.addressSelected.emit(Address.emptyAddress);
      }
    });
  }

  setupAddressParser() {
    this.place$
      .pipe(
        takeUntil(this.destroy$),
        filter(Boolean),
        map((place: google.maps.places.PlaceResult) => Address.parseAddress(place)),
        tap(() => {
          // save query used to select this address to avoid clearing address on blur
          this.existingAddress = this.addressQuery.value;
          this.hasEnteredAddress = true;
          this.hasPrediction = true;
        }),
      )
      .subscribe(parsedAddress => {
        this.addressSelected.emit(parsedAddress);
        this.changeDetectorRef.markForCheck();
      });
  }

  setupMapListener() {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(this.placesElementRef.nativeElement);
    this.autoComplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ['address'],
    });
    this.placeChangedListener = this.autoComplete.addListener('place_changed', () =>
      this.updatePlace(this.autoComplete.getPlace()),
    );
  }

  predictAddress() {
    // no query entered
    if (!this.addressQuery.value) {
      this.inputTouched.emit();
      return;
    }

    if (
      // not ready yet, or
      (!this.autoCompleteService ||
        // query is unchanged, and this is not for prefilled registration
        this.addressQuery.value === this.existingAddress) &&
      !this.prefilledRegistration
    ) {
      return;
    }

    // We need a timeout in because the blur is firing before the 'place_changed' observable.
    // https://stackoverflow.com/questions/32193020/events-other-than-place-changed-for-google-maps-autocomplete
    setTimeout(() => {
      if (this.autoCompleteService) {
        this.autoCompleteService.getPlacePredictions(
          { input: this.addressQuery.value, types: ['address'] },
          (placePredictions: google.maps.places.AutocompletePrediction[]) => {
            if (!placePredictions || placePredictions.length !== 1) {
              if (!this.hasPrediction) {
                this.addressSelected.emit(Address.emptyAddress);
              }
              return;
            }

            const placePrediction = placePredictions[0];
            this.addressQuery.setValue(placePrediction.description);
            this.placesService.getDetails({ placeId: placePrediction.place_id }, placeResult => {
              this.updatePlace(placeResult);
            });
          },
        );
      }
    }, 200);

    this.addressQuery.markAsDirty();
  }

  updatePlace(place: google.maps.places.PlaceResult) {
    this.place$.next(place);
  }

  markAsTouchedAndDirty(): void {
    this.addressQuery.markAsTouched();
    this.addressQuery.markAsDirty();
  }
}
