import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/core/auth.service';

import { RedirectPromptModalComponent } from '../redirect-prompt-modal.component';

@Component({
  selector: 'om-logout-prompt-modal',
  templateUrl: '../redirect-prompt-modal.component.html',
  styleUrls: ['../redirect-prompt-modal.component.scss'],
})
export class LogoutPromptModalComponent extends RedirectPromptModalComponent {
  constructor(private authService: AuthService, protected modal: NgbActiveModal) {
    super(modal);
  }

  redirect(): void {
    this.authService.logout(this.redirectUrl, false);
    super.redirect();
  }
}
