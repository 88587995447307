import { gql } from 'apollo-angular';

export const elistB2bCompany = gql`
  fragment elistB2bCompany on B2bCompany {
    id
    b2bEmailDomains
    includesDependent
    verifyDependentInfo
    activationCodeRetrievableViaEmail
    __typename
  }
`;

export const dependents = gql`
  fragment dependents on ScrubbedWhitelistedEmployee {
    id
    registered
    __typename
  }
`;
