<div class="modal-header p-0 text-center">
  <div class="w-100 card bg-dark text-white border-0 rounded-bottom-0">
    <img class="card-img" src="/assets/images/download-app-modal-hero.jpg" alt="Download App Hero Image" />
    <div class="card-img-overlay mt-4 mx-auto w-75">
      <h5 class="card-title">Connect to on-demand care over video from our mobile apps</h5>
      <p class="card-text">Available to all members, at no charge.</p>
    </div>
  </div>

  <omgui-close-x
    type="button"
    class="btn-close position-absolute p-3"
    height="32"
    width="32"
    stroke="white"
    backgroundOpacity="0.3"
    backgroundFill="#1A1A1A"
    fill="none"
    (click)="modal.dismiss()"
  ></omgui-close-x>
</div>

<div class="modal-body my-3 text-center d-block d-sm-none">
  <p>Available to all members, at no charge. Click the button to see the One Medical App in the app store.</p>
  <a [routerLink]="linksService.appStoreRedirect" class="btn btn-primary">Download Mobile App</a>
</div>

<div class="modal-body my-3 d-none d-sm-flex text-center">
  <div class="modal-body-item border-right">
    <div class="mx-3 h-100 d-flex justify-content-between flex-column">
      <h6 class="m-0">Text Download Link</h6>
      <p class="m-0">Have a text sent with download link</p>
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <om-phone-number-input
          [overrideDisplayError]="true"
          [formControlName]="formControlName"
          (errorMessageEmitter)="setErrorMessage($event)"
        ></om-phone-number-input>
        <span class="text-danger" *ngIf="formErrorMessage">{{ formErrorMessage }}</span>
        <button class="btn btn-primary" type="submit" [disabled]="submitting">Send Link</button>
      </form>
    </div>
  </div>

  <div class="modal-body-item">
    <div class="mx-3">
      <h6>QR Code Download</h6>
      <p>Point phone camera at the QR code and click link</p>
      <img class="qr-code" src="/assets/images/app-store-redirect-qr-code.svg" alt="QR Code to download mobile app" />
    </div>
  </div>
</div>
