import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { OmguiProviderProfileBubbleModule } from '@omgui/omgui-profile-bubble/omgui-provider-profile-bubble.component';

import { ComponentSize } from '../component-size';
import { Profile } from '../omgui-profile-bubble/omgui-profile-bubble.component';

export enum NotificationType {
  remoteAppointment = 'remote-appointment',
  inOfficeAppointment = 'in-office-appointment',
  unreadMessage = 'unread-message',
}

@Component({
  selector: 'omgui-provider-notification-avatar',
  templateUrl: './omgui-provider-notification-avatar.component.html',
  styleUrls: [
    '../omgui-profile-bubble/omgui-profile-bubble-sizes.scss',
    './omgui-provider-notification-avatar.component.scss',
  ],
})
export class OmguiProviderNotificationAvatarComponent {
  /** Sets the size of the component to a predefined value. Only medium and medium-small are verified to look proper. */
  @Input() size: ComponentSize.medium | ComponentSize.mediumSmall = ComponentSize.medium;

  /** The notification type, either remote appointment or in office appointment or unread message */
  @Input() notificationType: NotificationType;

  /** Controls a visual indicator that highlights the avatar */
  @Input() active = false;

  /** Provider&apos;s name and profile image URL */
  @Input() profile: Profile;

  /** @ignore */
  readonly componentSize = ComponentSize;
  /** @ignore */
  readonly uuid = uuid();

  /** @ignore */
  readonly NotificationType = NotificationType;
}

@NgModule({
  declarations: [OmguiProviderNotificationAvatarComponent],
  imports: [CommonModule, OmguiProviderProfileBubbleModule],
  exports: [OmguiProviderNotificationAvatarComponent],
})
export class OmguiProviderNotificationAvatarModule {}
