import { Component, OnInit } from '@angular/core';

import { AppStoreRedirectService } from '@app/shared/app-store-redirect.service';
import { WindowService } from '@app/utils/window.service';

@Component({
  selector: 'om-app-store-redirect',
  template: ``,
})
export class AppStoreRedirectComponent implements OnInit {
  userAgent: string;

  constructor(private windowService: WindowService, private appStoreRedirectService: AppStoreRedirectService) {}

  ngOnInit() {
    this.windowService.redirect(this.appStoreRedirectService.redirectUrl);
  }
}
