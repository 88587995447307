import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';

@Injectable()
export class MembershipRenewGuardService implements CanActivate {
  constructor(private router: Router, private links: LinksService, private membershipService: MembershipService) {}

  // Manages the legacy route: /renew
  // Redirects to consumer and enterprise
  // Many emails went out recently directing customers to `/renew`
  // So we need to make sure this directs people to the right place
  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    this.membershipService.getMembership();
    return this.membershipService.membership$.pipe(
      take(1),
      map((membership: Membership) => {
        let redirectUrl;
        let allow = false;

        if (!membership.isExpired()) {
          // Navigate to settings if not expired
          redirectUrl = this.links.membershipSettings;
        } else if (membership.isOMNow()) {
          redirectUrl = this.links.membershipRenewOMNow;
        } else if (membership.isB2b()) {
          redirectUrl = this.links.membershipRenewEnterprise;
        } else {
          redirectUrl = this.links.membershipRenewConsumer;
          allow = true;
        }

        // Note:
        // can't use preserveQueryParams because when coming from login the query params
        // would not be in previous path - but will be in activatedRouteSnapshot

        this.router.navigate([redirectUrl], { queryParams: activatedRouteSnapshot.queryParams, replaceUrl: true });

        return allow;
      }),
    );
  }
}

// Manages the new route: /renew/enterprise
// Block non-expired members
// Only allow B2b members on the enterprise renew page, send everyone else to consumer
@Injectable()
export class MembershipB2bRenewGuardService implements CanActivate {
  constructor(private router: Router, private links: LinksService, private membershipService: MembershipService) {}

  canActivate(): boolean | Observable<boolean> {
    this.membershipService.getMembership();
    return this.membershipService.membership$.pipe(
      take(1),
      map((membership: Membership) => {
        // Navigate to settings if not expired
        if (!membership.isExpired()) {
          this.router.navigate([this.links.membershipSettings], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        } else if (membership.isOMNow()) {
          this.router.navigate([this.links.membershipRenewOMNow], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        } else if (membership.isB2b()) {
          return true;
        } else {
          this.router.navigate([this.links.membershipRenewConsumer], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        }
      }),
    );
  }
}

// Manages the new route: /renew/consumer
// Block non-expired members
// Only allow non B2b members on the consumer renew page, send everyone else to B2b
@Injectable()
export class MembershipConsumerRenewGuardService implements CanActivate {
  constructor(private router: Router, private links: LinksService, private membershipService: MembershipService) {}

  canActivate(): boolean | Observable<boolean> {
    this.membershipService.getMembership();
    return this.membershipService.membership$.pipe(
      take(1),
      map((membership: Membership) => {
        // Navigate to settings if not expired
        if (!membership.isExpired()) {
          this.router.navigate([this.links.membershipSettings], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        } else if (membership.isConsumer()) {
          return true;
        } else if (membership.isOMNow()) {
          this.router.navigate([this.links.membershipRenewOMNow], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        } else {
          this.router.navigate([this.links.membershipRenewEnterprise], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        }
      }),
    );
  }
}

// Manages the new route: /renew/omnow
// Block non-expired members
// Only allow non B2b members who are OM Now on the OM Now renew page, send everyone else to their locations
@Injectable()
export class MembershipOMNowRenewGuardService implements CanActivate {
  constructor(private router: Router, private links: LinksService, private membershipService: MembershipService) {}

  canActivate(): boolean | Observable<boolean> {
    this.membershipService.getMembership();
    return this.membershipService.membership$.pipe(
      take(1),
      map((membership: Membership) => {
        // Navigate to settings if not expired
        if (!membership.isExpired()) {
          this.router.navigate([this.links.membershipSettings], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        } else if (membership.isOMNow()) {
          return true;
        } else if (membership.isB2b()) {
          this.router.navigate([this.links.membershipRenewEnterprise], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        } else {
          this.router.navigate([this.links.membershipRenewConsumer], { replaceUrl: true, queryParamsHandling: 'preserve' });
          return false;
        }
      }),
    );
  }
}
