import { Address } from './address';
import { ServiceArea } from './service-area';
export class Office {
  id: number;
  name: string;
  phone: string;
  email: string;
  imageUrl: string;
  address: Address;
  serviceAreaId: number;
  b2bCompanyIds: number[];
  permalink: string;
  serviceArea: ServiceArea;

  constructor() {}

  static fromApiV2(officeObject): Office {
    const office = new Office();
    office.id = parseInt('' + officeObject.id, 10);
    office.name = officeObject.name;
    office.phone = officeObject.phone;
    office.imageUrl = officeObject.image_url_large;
    office.email = `${officeObject.email_account}@onemedical.com`;
    office.serviceAreaId = officeObject.service_area_id;
    office.b2bCompanyIds = officeObject.b2b_company_ids;
    office.permalink = officeObject.permalink;

    const address = new Address();
    address.address1 = officeObject.address1;
    address.address2 = officeObject.address2;
    address.city = officeObject.city;
    address.state = officeObject.state;
    address.zip = officeObject.zip;
    address.latitude = officeObject.latitude;
    address.longitude = officeObject.longitude;
    office.address = address;

    return office;
  }

  static fromGraphQL(officeObject): Office {
    const office = new Office();

    office.id = parseInt('' + officeObject.id, 10);
    office.name = officeObject.name;
    office.b2bCompanyIds = officeObject.b2bCompanyIds;
    office.serviceArea = ServiceArea.fromGraphQL(officeObject.serviceArea);

    return office;
  }
}
