import { GraphQLAppointment } from './appointment';

export const DATE_FORMAT = 'EEE MM/dd/yy';
export const TIME_FORMAT = 'h:mm aaa zz';

type RequiredAppointmentValues = Pick<GraphQLAppointment, 'isRemote' | 'timezoneTzinfo'>;

export function chooseDisplayTimezone(patientTimezone: string, appointment: RequiredAppointmentValues): string {
  return appointment.isRemote ? patientTimezone : appointment.timezoneTzinfo;
}
