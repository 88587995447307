<div class="flash">
  <!-- OM "alert" will map to Bootstrap's "warning" type-->
  <ngb-alert *ngFor="let alert of alerts" class="text-center" type="warning" (closed)="remove(alert, 'alert')">{{
    alert
  }}</ngb-alert>
  <!-- OM "notice" will map to Bootstrap's "info" type-->
  <ngb-alert *ngFor="let notice of notices" class="text-center" type="info" (closed)="remove(notice, 'notice')">{{
    notice
  }}</ngb-alert>
  <!-- OM "error" will map to Bootstrap's "danger" type-->
  <ngb-alert *ngFor="let error of errors" class="text-center" type="danger" (closed)="remove(error, 'error')">{{
    error
  }}</ngb-alert>
  <!-- OM "success" will map to Bootstrap's "success" type-->
  <ngb-alert
    *ngFor="let success of successes"
    class="text-center"
    type="success"
    (closed)="remove(success, 'success')"
    >{{ success }}</ngb-alert
  >
</div>
