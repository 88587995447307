<form [formGroup]="address">
  <div *ngIf="!fullAddressFormVisible">
    <div class="mb-2" *ngIf="!fullAddressFormVisible && hasAutocompleteError">
      <a href (click)="showFullAddressForm($event)">Having trouble? Enter your address the old fashioned way.</a>
    </div>
    <div class="mb-2">
      <om-address-autocomplete
        #autocomplete
        [existingAddress]="existingAddress"
        [class.has-error]="hasAutocompleteError"
        (addressSelected)="addressSelected($event)"
        [enableAutoFocus]="enableAutoFocus"
        [prefilledRegistration]="prefilledRegistration"
      ></om-address-autocomplete>
    </div>
    <div class="mb-2">
      <a
        href
        *ngIf="!address2Shown"
        (click)="showAddress2($event)"
        class="address-2 mb-2"
        data-cy="address-line-2-button"
      >
        Add apt, suite, floor
      </a>
    </div>

    <div class="form-group" [hidden]="!address2Shown">
      <label for="address-line-2">Address Line 2</label>
      <input
        type="text"
        id="address-line-2"
        class="form-control"
        formControlName="address2"
        placeholder="Apt, Suite, Floor"
        aria-labelledby="address-label"
        data-cy="address-line-2-input"
      />
    </div>
  </div>

  <om-address-basic-input *ngIf="fullAddressFormVisible" [address]="address"></om-address-basic-input>

  <div class="form-group">
    <span class="error" *ngIf="hasAutocompleteError">Sorry, looks like something's missing from that address.</span>
  </div>
</form>
