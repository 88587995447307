import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import range from 'lodash-es/range';
import { FormGroupState } from 'ngrx-forms';

import { IEmailVerification } from '@app/registration/consumer/email-verification.reducer';

@Component({
  selector: 'om-email-verification-registration',
  styleUrls: ['../form-input.scss'],
  templateUrl: './email-verification-registration.component.html',
})
export class EmailVerificationRegistrationComponent {
  @Input() form: FormGroup;
  @Input() formGroupState?: FormGroupState<IEmailVerification>;
  @Input() submitting;
  @Input() preferredEmail;
  @Input() errorMessage;
  @Output() submitCode = new EventEmitter<boolean>();
  @Output() skip = new EventEmitter<boolean>();
  @Output() resendEmail = new EventEmitter<boolean>();
  @Output() emailTechSupport = new EventEmitter<boolean>();

  ENTER_KEY = 'Enter';

  onSubmit() {
    if (this.form) {
      this.form.controls.verificationCode.markAsTouched();
      this.form.controls.verificationCode.markAsDirty();
    }
    this.submitCode.emit();
  }

  onSkip() {
    this.skip.emit();
  }

  onResendEmail(e: Event) {
    e.preventDefault();
    this.resendEmail.emit();
  }

  onEmailTechSupport(_e: Event) {
    this.emailTechSupport.emit();
  }

  filterNonNumeric(e: KeyboardEvent) {
    if (e.key === this.ENTER_KEY) {
      e.preventDefault();
      this.onSubmit();
      return;
    }
    const keys = range(10).map(n => n.toString());
    return keys.indexOf(e.key) > -1;
  }

  hasError(): boolean {
    return !!this.errorMessage && this.errorMessage !== '';
  }
}
