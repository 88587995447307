import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { UserService } from '@app/core/user.service';
import { PatientMobileDsuEligibleGraphQL } from '@app/registration';
import { MobileDsuEligibleService } from '@app/registration/mobile-dsu-eligible.service';
import { Address } from '@app/shared/address';
import { minimumAge } from '@app/shared/date-validator.directive';

import { EnterpriseRegistrationAnalyticsService } from '../../registration/enterprise/enterprise-registration-analytics.service';
import { AddressOptionInputComponent } from '../address-option-input/address-option-input.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { EmailInputComponent } from '../email-input/email-input.component';
import { PhoneNumberInputComponent } from '../phone-number-input/phone-number-input.component';
import { ToggleOption } from '../toggle/toggle.component';
import { User } from '../user';

@Component({
  selector: 'om-adult-direct-signup-form',
  templateUrl: './adult-direct-signup-form.component.html',
  styleUrls: ['../form-input.scss', './adult-direct-signup-form.component.scss'],
})
export class AdultDirectSignupFormComponent implements OnInit {
  @ViewChild('firstName') firstName;
  @ViewChild('email') email: EmailInputComponent;
  @ViewChild('phoneNumber') phoneNumber: PhoneNumberInputComponent;
  @ViewChild('dateOfBirth') dateOfBirth: DateInputComponent;
  @ViewChild('address') address: AddressOptionInputComponent;
  @Output() canceled = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private mobileDsuEligibleService: MobileDsuEligibleService,
  ) {}

  minimumAge = 18;
  showGenderDetails = false;
  showPreferredName = false;
  sexOptions: ToggleOption[] = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  directSignupError: string;
  createdPatient: User = null;
  submitting = false;

  form = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    preferredName: '',
    email: ['', Validators.required],
    phoneNumber: '',
    dob: this.formBuilder.control({ date: '' }, { validators: [minimumAge(this.minimumAge)] }),
    genderDetails: '',
    sex: ['', Validators.required],
    sameAddress: true,
  });

  ngOnInit() {
    this.enterpriseRegistrationAnalyticsService.adultDirectSignupPageViewed();

    this.form.controls.sameAddress.valueChanges.subscribe(checked => {
      if (checked) {
        this.form.removeControl('address');
      } else {
        this.form.addControl('address', this.formBuilder.group(Address.buildBasicAddressControlsConfig()));
      }
      this.form.updateValueAndValidity();
    });
  }

  onShowGenderDetailsClick(event: MouseEvent) {
    event.preventDefault();
    this.enterpriseRegistrationAnalyticsService.directSignupGenderDetailsExpanded();

    this.showGenderDetails = true;
  }

  onSubmit() {
    this.markAsTouchedAndDirty();

    if (!this.form.valid) {
      return;
    }

    this.enterpriseRegistrationAnalyticsService.directSignupSubmitted();
    this.submitting = true;
    this.userService.createDirectSignupUser(this.patient).subscribe(
      patient => {
        this.createdPatient = patient;
        this.submitting = false;
        this.userService.getUser(true);
        this.mobileDsuEligibleService.getEligibility();
        this.enterpriseRegistrationAnalyticsService.directSignupConfirmationPageViewed();
      },
      error => {
        this.directSignupError = error;
        this.submitting = false;
      },
    );
  }

  markAsTouchedAndDirty() {
    this.directSignupError = null;

    this.form.controls.firstName.markAsDirty();
    this.form.controls.firstName.markAsTouched();
    this.form.controls.lastName.markAsDirty();
    this.form.controls.lastName.markAsTouched();

    this.email.markAsTouchedAndDirty();
    this.dateOfBirth.markAsTouchedAndDirty();

    this.phoneNumber.markAsTouchedAndDirty();
    this.form.controls.sex.markAsTouched();
    this.form.controls.sex.markAsDirty();

    if (this.form.controls.address) {
      this.address.markAsTouchedAndDirty();
    }
  }

  get dateOfBirthError(): boolean {
    const { errors } = this.form.controls.dob;
    return !!errors && !!errors.age && !errors.age.valid;
  }

  get sexError(): boolean {
    const control = this.form.controls.sex;
    return control.dirty && control.touched && control.invalid;
  }

  get patient(): User {
    const user = new User();

    user.firstName = this.form.value.firstName;
    user.lastName = this.form.value.lastName;
    user.preferredName = this.form.value.preferredName;
    user.email = this.form.value.email;
    user.dob = this.form.value.dob.date;
    user.genderDetails = this.form.value.genderDetails;
    user.gender = this.form.value.sex;
    user.phoneNumber = this.form.value.phoneNumber;
    user.sameAddress = this.form.value.sameAddress;

    if (this.form.controls.address) {
      user.address = this.addressValue;
    }

    return user;
  }

  get addressValue(): Address {
    const address = new Address();

    address.address1 = this.form.value.address.address1;
    address.address2 = this.form.value.address.address2;
    address.city = this.form.value.address.city;
    address.state = this.form.value.address.state;
    address.stateId = this.form.value.address.stateId;
    address.zip = this.form.value.address.zip;

    return address;
  }

  cancelClicked() {
    this.enterpriseRegistrationAnalyticsService.directSignupCanceled();
    this.canceled.emit();
  }
}
