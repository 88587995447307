import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { ApiService } from '@app/core/api.service';
import { NavigatorService } from '@app/core/navigator.service';
import { UserService } from '@app/core/user.service';
import { emailValid } from '@app/shared/email-validator';

@Component({
  selector: 'om-invite-friends-modal',
  templateUrl: './invite-friends-modal.component.html',
  styleUrls: ['./invite-friends-modal.component.scss'],
})
export class InviteFriendsModalComponent implements OnInit, OnDestroy {
  referralLink: string;
  referralDiscount: string = null;
  showThankYou = false;
  invitePending = false;
  inviteeEmail: string;

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private modal: NgbActiveModal,
    private userService: UserService,
    private navigatorService: NavigatorService,
  ) {}

  ngOnInit() {
    this.userService.user$.pipe(take(1)).subscribe(user => {
      this.referralLink = user.referralLinkWebSocial;

      if (user.referralDiscount) {
        this.referralDiscount = user.referralDiscount.getDiscountString();
      }
    });
    this.userService.getUser();

    this.analyticsService.trackReferralModalOpen();
  }

  ngOnDestroy() {
    this.analyticsService.trackReferralModalClosed();
  }

  validateAndSubmit(event, emailInput) {
    event.preventDefault();

    if (this.validateEmail(emailInput)) {
      this.sendReferral(this.inviteeEmail);
    }
  }

  copyLink(button: Element) {
    this.navigatorService.writeToClipboard(this.referralLink).then(() => {
      this.showCopySuccess(button);
      this.analyticsService.trackReferralLinkCopied();
    });
  }

  inviteMore() {
    this.showThankYou = false;

    this.analyticsService.trackInviteMoreFriends();
  }

  closeModal() {
    this.modal.close();
  }

  private showCopySuccess(copyIcon: Element) {
    copyIcon.classList.add('success');

    setTimeout(
      _copyIcon => {
        _copyIcon.classList.remove('success');
        _copyIcon.classList.add('fade-checkmark');
      },
      1000,
      copyIcon,
    );
    setTimeout(
      _copyIcon => {
        _copyIcon.classList.remove('fade-checkmark');
      },
      1500,
      copyIcon,
    );
  }

  private validateEmail(element) {
    const email = element.value;

    if (emailValid(email)) {
      element.classList.remove('is-invalid');
      element.classList.add('is-valid');

      this.inviteeEmail = email;
      return true;
    } else {
      element.classList.remove('is-valid');
      element.classList.add('is-invalid');

      this.inviteeEmail = '';
      this.analyticsService.trackReferralSubmissionError();
      return false;
    }
  }

  private sendReferral(email) {
    this.invitePending = true;

    const requestPayload = this.formatParams(email);

    this.apiService
      .post('/api/v2/patient/referrals', requestPayload, true)
      .pipe(take(1))
      .subscribe(response => {
        this.analyticsService.trackReferralSubmitted(email);
        this.showThankYou = true;
        this.invitePending = false;
      });
  }

  private formatParams(email) {
    return {
      referral: {
        emails: [
          {
            email: email,
            referral_type: 'friend',
          },
        ],
      },
    };
  }
}
