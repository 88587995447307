<div class="card d-block">
  <omgui-close-x
    *ngIf="canDismiss"
    class="dismiss p-2"
    role="button"
    aria-label="Permanently hide the program card"
    height="32"
    width="32"
    backgroundFill="#FFFFFF"
    backgroundOpacity="0.6"
    (click)="dismissCard($event)"
  ></omgui-close-x>
  <div class="d-flex flex-sm-row flex-column">
    <div>
      <button class="promo-card-image p-0 border-0 h-100" (click)="imageClick.emit($event)">
        <img [src]="imageUrl" class="img-fluid" />
      </button>
    </div>
    <div class="p-4 col-md-8">
      <omgui-badge [text]="tag"></omgui-badge>
      <h3 class="card-title">{{ title }}</h3>
      <p class="mb-4 d-none d-sm-block">
        {{ description }}
      </p>
      <ng-content select="[program-promotion-card-actions]"></ng-content>
    </div>
  </div>
</div>
