<div
  class="d-none d-sm-flex justify-content-between accordion-header py-3 border rounded-top"
  (click)="onClick()"
  [ngClass]="{ 'rounded-bottom': shouldRoundBottom }"
  [attr.aria-expanded]="isExpanded"
>
  <div>
    <ng-container *ngTemplateOutlet="accordionTitle"></ng-container>
    <p class="text-muted mb-0">{{ subtitle }}</p>
  </div>

  <div class="d-flex align-items-center">
    <ng-container *ngTemplateOutlet="progressBar"></ng-container>

    <ng-container *ngTemplateOutlet="chevron; context: { $implicit: isExpanding || isExpanded }"></ng-container>
  </div>
</div>

<div
  class="d-flex d-sm-none justify-content-between accordion-header py-3 inline-icon-with-text border rounded-top"
  [ngClass]="{ 'rounded-bottom': shouldRoundBottom }"
  (click)="onClick()"
  [attr.aria-expanded]="isExpanded"
>
  <div>
    <ng-container *ngTemplateOutlet="accordionTitle"></ng-container>

    <div class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="progressBar"></ng-container>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="chevron; context: { $implicit: isExpanding || isExpanded }"></ng-container>
</div>

<button class="sr-only" aria-label="Expand" (click)="onClick()" [attr.aria-expanded]="isExpanded"></button>

<div
  [ngbCollapse]="true"
  #collapse="ngbCollapse"
  (hidden)="onCollapseFinished()"
  (shown)="onExpandFinished()"
  class="border-left border-right border-bottom rounded-bottom"
>
  <omgui-list-card [borderless]="true">
    <omgui-list-card-item *ngFor="let item of items" [data]="item.listCardData">
      <ng-template omgui-list-card-item-icon>
        <img
          class="mr-2 icon"
          [alt]="item.listCardData.label + ' ' + (item.completed ? 'is completed' : 'is not completed')"
          [src]="'/assets/images/icons/' + (item.completed ? 'circle-checked.svg' : 'circle.svg')"
        />
      </ng-template>
    </omgui-list-card-item>
  </omgui-list-card>
</div>

<ng-template #accordionTitle>
  <h6 class="mb-1">{{ title }}</h6>
</ng-template>

<ng-template #progressBar>
  <span>{{ uncompletedItemsCount }} {{ singularOrPluralItems }} remaining</span>
  <ngb-progressbar class="mx-3" type="dark" [value]="completionPercentage" aria-hidden="true"></ngb-progressbar>
</ng-template>

<ng-template #chevron let-opened>
  <omgui-collapse-state-icon [isOpen]="opened"></omgui-collapse-state-icon>
</ng-template>
