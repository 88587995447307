<div class="row flex-column flex-sm-row pl-sm-3">
  <div class="col col-md-6 py-sm-4 pl-sm-4 pr-sm-0">
    <img class="card-img" [src]="imageUrl" [alt]="imageAltText" data-cy="omgui-feature-card-image" />
  </div>
  <div class="col col-sm-6 pl-sm-0">
    <div class="card-body">
      <h3 class="card-title" data-cy="omgui-feature-card-title">{{ title }}</h3>
      <p class="card-text" data-cy="omgui-feature-card-description">{{ description }}</p>
      <div *ngIf="primaryAction || secondaryAction">
        <ng-container [ngTemplateOutlet]="primaryAction?.templateRef"></ng-container>
        <ng-container [ngTemplateOutlet]="secondaryAction?.templateRef"></ng-container>
      </div>
    </div>
  </div>
</div>
