import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
/**
 * @deprecated Use DateFormatPipe instead
 */
@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(date: string | Date | moment.Moment, format: string) {
    return moment(date).format(format);
  }
}
