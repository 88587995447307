import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsService, FLOW } from '@app/core/analytics.service';
import { FLOW_ACCOUNT_UPDATE, FLOW_HOME_PAGE_NAVIGATION } from '@app/core/mixpanel.constants';

@Injectable({
  providedIn: 'root',
})
export class NavbarAnalyticsService extends AnalyticsService {
  private readonly SUBMODULE = 'Navigation Bar';

  trackHealthRecordLink(): Observable<void> {
    return super.trackHealthRecordLink({
      submodule: this.SUBMODULE,
    });
  }

  trackBookVisitLink(): Observable<void> {
    return super.trackBookVisitLink({
      submodule: this.SUBMODULE,
    });
  }

  trackHomeLink(): Observable<void> {
    return this.trackWithDefaultProperties('Home Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: this.SUBMODULE,
    });
  }

  trackTasksLink(): Observable<void> {
    return this.trackWithDefaultProperties('Tasks Clicked', {
      flow: FLOW.PATIENT_TASKS,
      submodule: this.SUBMODULE,
    });
  }

  trackMessagesLink(): Observable<void> {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: FLOW.PATIENT_TASKS,
      submodule: this.SUBMODULE,
    });
  }

  trackRegisterChildLinkSelected(): Observable<void> {
    return this.trackWithDefaultProperties('Register Child Link Selected', {
      flow: FLOW_ACCOUNT_UPDATE,
      submodule: this.SUBMODULE,
    });
  }

  trackMyProgramSelected({ programName }: { programName: string }): Observable<void> {
    return this.trackWithDefaultProperties('My Program Selected', {
      program: programName,
      submodule: this.SUBMODULE,
    });
  }
}
