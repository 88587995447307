import { Injectable } from '@angular/core';

import { EmployeeIdConfig } from '@app/registration/enterprise/employee-id/employee-id-config';
import { PediatricDirectSignupSuccessConfig } from '@app/registration/enterprise/pediatric-direct-signup-success/pediatric-direct-signup-success-config';
import { TermsOfServiceConfig } from '@app/registration/enterprise/terms-of-service/terms-of-service-config';

import { AccountSetUpConfig } from './account-set-up/account-set-up-config';
import { ActivationCodeConfig } from './activation-code/activation-code-config';
import { AdultDirectSignupConfig } from './adult-direct-signup/adult-direct-signup-config';
import { CreateAccountConfig } from './create-account/create-account-config';
import { DirectSignupConfig } from './direct-signup/direct-signup-config';
import { DobSexConfig } from './dob-sex/dob-sex-config';
import { EmailVerificationConfig } from './email-verification/email-verification-config';
import { MembershipSelectionConfig } from './membership-selection/membership-selection-config';
import { IRegistrationStep } from './registration-step';
import { StepName } from './registration-step-name';
import { ServiceAreaConfig } from './service-area-selection/service-area-config';
import { SuccessConfig } from './success/success-config';
import { VerifiedAccountSetUpConfig } from './verified-account-set-up/verified-account-set-up-config';
import { WorkEmailConfig } from './work-email/work-email-config';

@Injectable()
export class RegistrationNavigation {
  steps: Record<string, IRegistrationStep>;

  constructor(
    accountSetUpConfig: AccountSetUpConfig,
    activationCodeConfig: ActivationCodeConfig,
    createAccountConfig: CreateAccountConfig,
    emailVerificationConfig: EmailVerificationConfig,
    dobSexConfig: DobSexConfig,
    employeeIdConfig: EmployeeIdConfig,
    membershipSelectionConfig: MembershipSelectionConfig,
    serviceAreaConfig: ServiceAreaConfig,
    termsOfService: TermsOfServiceConfig,
    workEmailConfig: WorkEmailConfig,
    successConfig: SuccessConfig,
    directSignupConfig: DirectSignupConfig,
    pediatricDirectSignupSuccessConfig: PediatricDirectSignupSuccessConfig,
    adultDirectSignupConfig: AdultDirectSignupConfig,
    verifiedAccountSetUpConfig: VerifiedAccountSetUpConfig,
  ) {
    this.steps = {
      [StepName.accountSetUp]: accountSetUpConfig,
      [StepName.activationCode]: activationCodeConfig,
      [StepName.createAccount]: createAccountConfig,
      [StepName.dobSex]: dobSexConfig,
      [StepName.emailVerification]: emailVerificationConfig,
      [StepName.employeeId]: employeeIdConfig,
      [StepName.membershipSelection]: membershipSelectionConfig,
      [StepName.serviceArea]: serviceAreaConfig,
      [StepName.termsOfService]: termsOfService,
      [StepName.workEmail]: workEmailConfig,
      [StepName.success]: successConfig,
      [StepName.directSignup]: directSignupConfig,
      [StepName.pediatricDirectSignupSuccess]: pediatricDirectSignupSuccessConfig,
      [StepName.adultDirectSignup]: adultDirectSignupConfig,
      [StepName.verifiedAccountSetUp]: verifiedAccountSetUpConfig,
    };
  }

  getStep(stepName: StepName): IRegistrationStep {
    return this.steps[stepName];
  }
}
