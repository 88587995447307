import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { AppointmentRemoteLink } from '@app/appointment/appointment-join/__generated__/AppointmentRemoteLink';
import { AppointmentRemoteLinkGraphqlService } from '@app/appointment/appointment-join/appointment-remote-link-graphql.service';
import { ConfigService } from '@app/core/config.service';
import { PageState } from '@app/pipes/page-state/page-state';
import { AppStoreRedirectService } from '@app/shared';
import { WindowService } from '@app/utils/window.service';

@Component({
  selector: 'om-appointment-join',
  templateUrl: './appointment-join.component.html',
  styleUrls: ['./appointment-join.component.scss'],
})
export class AppointmentJoinComponent implements OnInit, OnDestroy {
  appStoreUrl: string;
  zoomUrl: string;
  pageState: PageState = PageState.LOADING;

  private destroy$ = new Subject<void>();

  constructor(
    public configService: ConfigService,
    private windowService: WindowService,
    private appStoreRedirectService: AppStoreRedirectService,
    private appointmentRemoteLinkGraphqlService: AppointmentRemoteLinkGraphqlService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Join Visit');

    this.activatedRoute.paramMap
      .pipe(
        map(params => params.get('id')),
        switchMap(
          (appointmentId: string) =>
            this.appointmentRemoteLinkGraphqlService.watch({
              id: appointmentId,
            }).valueChanges,
        ),
        map((result: ApolloQueryResult<AppointmentRemoteLink>) => {
          const link = result.data.appointment?.remoteMeetingLink;
          if (!link) {
            throw new Error('Appointment could not be found.');
          }

          return link;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (link: string) => {
          this.pageState = PageState.SUCCESS;
          this.zoomUrl = link;
        },
        error: error => {
          this.pageState = PageState.ERROR;
        },
      });

    this.appStoreUrl = this.appStoreRedirectService.redirectUrl;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
