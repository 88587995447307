<div ngbDropdown container="body">
  <button
    ngbDropdownToggle
    type="button"
    class="btn btn-alternate dropdown-toggle inline-icon-with-text"
    [disabled]="disabled"
    data-cy="appointment-actions"
  >
    {{ name }}
  </button>
  <div ngbDropdownMenu>
    <button type="button" ngbDropdownItem [routerLink]="detailsLink" data-cy="view-details">
      {{ viewDetailsButtonLabel }}
    </button>
    <button type="button" ngbDropdownItem (click)="showCancelConfirmation()" data-cy="cancel">
      {{ cancelButtonLabel }}
    </button>
    <button type="button" ngbDropdownItem (click)="rescheduleAppointment()" data-cy="reschedule">
      {{ rescheduleButtonLabel }}
    </button>
    <button type="button" ngbDropdownItem (click)="showRunningLateConfirmation()" data-cy="running-late">
      {{ runningLateButtonLabel }}
    </button>
  </div>
</div>

<ng-template #confirmationModal let-modal>
  <om-confirmation-modal [title]="modalTitle" [body]="modalBody" [close]="modal.close"></om-confirmation-modal>
</ng-template>
