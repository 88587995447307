import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { AuthService } from '@app/core/auth.service';
import { ConfigService } from '@app/core/config.service';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { ToastComponent } from '@app/shared';
import { OmRecaptchaComponent } from '@app/shared/recaptcha/om-recaptcha.component';
import { ToastService } from '@app/shared/toast.service';

import { EnterpriseRegistration } from './enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from './enterprise-registration-analytics.service';
import { RegistrationStepDirective } from './registration-step.directive';

@Component({
  selector: 'om-registration-container',
  styleUrls: ['../../shared/form-input.scss'],
  templateUrl: './registration-container.component.html',
})
export class RegistrationContainerComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @Input() initialStepName = StepName.workEmail;
  @ViewChild('captcha', { static: true }) captcha: OmRecaptchaComponent;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  fastForwarding = false;

  private component: RegistrationStepDirective;
  private componentSubmitSubscription: Subscription;
  private componentLogInSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private stepSubmitSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public attemptedPathService: AttemptedPathService,
    public authService: AuthService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public config: ConfigService,
    private enterpriseRegistration: EnterpriseRegistration,
    private router: Router,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    const { initialStepName } = this.route.snapshot.data;
    const { queryParams } = this.route.snapshot;
    if (Object.keys(queryParams).length > 0) {
      this.enterpriseRegistration.patchParams(queryParams);
      this.fastForwarding = true;
    }
    if (initialStepName) {
      this.initialStepName = initialStepName;
      this.fastForwarding = false;
    }
    this.toastService.toastMessage$.pipe(takeUntil(this.destroy$)).subscribe({
      next: message => this.toastComponent.show(message),
    });
    this.enterpriseRegistration.setCurrentStep(this.initialStepName);
  }

  ngAfterContentInit() {
    if (!this.fastForwarding) {
      this.loadComponent();
    }
  }

  ngAfterViewInit(): void {
    this.enterpriseRegistration.setUser();
    if (this.fastForwarding) {
      this.fastForward();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadComponent() {
    if (this.componentSubmitSubscription) {
      this.componentSubmitSubscription.unsubscribe();
    }
    if (this.stepSubmitSubscription) {
      this.stepSubmitSubscription.unsubscribe();
    }
    if (this.componentLogInSubscription) {
      this.componentLogInSubscription.unsubscribe();
    }
    if (!this.enterpriseRegistration.getCurrentStep()) {
      return;
    }

    const step = this.enterpriseRegistration.getCurrentStep();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(step.component);
    this.container.clear();
    const compRef = this.container.createComponent(componentFactory);
    this.component = compRef.instance;
    step.initComponent(<RegistrationStepDirective>compRef.instance, this.enterpriseRegistration);
    if (step.shouldFastForward) {
      this.fastForwarding = true;
      this.onSubmit();
    } else {
      this.enterpriseRegistration
        .getCurrentStep()
        .trackPageVisit(this.enterpriseRegistration, this.enterpriseRegistrationAnalyticsService);
      this.componentSubmitSubscription = this.component.submit.subscribe(() => {
        this.onSubmit();
      });
      this.component.isLoggedIn = this.isLoggedIn;
      this.componentLogInSubscription = this.component.login.subscribe(() => this.handleLogIn());
    }
  }

  percentComplete() {
    return this.enterpriseRegistration.percentComplete();
  }

  canGoBack() {
    return this.enterpriseRegistration.canGoBack();
  }

  onSubmit() {
    this.stepSubmitSubscription = this.enterpriseRegistration
      .getCurrentStep()
      .submit(this.enterpriseRegistration, this.captcha)
      .subscribe(
        (result: any) => {
          if (this.fastForwarding && this.enterpriseRegistration.getCurrentStep().shouldFastForward) {
            this.onSubmit();
          } else {
            this.fastForwarding = false;
            if (result) {
              this.loadComponent();
            }
          }
        },
        (err: Error) => {
          if (this.fastForwarding) {
            this.fastForwarding = false;
            this.loadComponent();
          } else {
            this.component.onError(err);
          }
        },
      );
  }

  onBack() {
    if (this.enterpriseRegistration.canGoBack()) {
      this.enterpriseRegistration.goBack();
      this.loadComponent();
    }
  }

  fastForward() {
    this.onSubmit();
  }

  handleLogIn() {
    this.enterpriseRegistrationAnalyticsService.loginClicked({
      module: this.enterpriseRegistration.getCurrentStep().MODULE,
      isWhitelist: this.enterpriseRegistration.isWhitelisted,
    });

    const params = this.enterpriseRegistration.redirectParams();
    this.authService.goLogin(`${this.pathName()}?${params}`);
  }

  pathName(): string {
    return window.location.pathname;
  }

  determineIfShouldTrackEvent() {
    return (
      (this.enterpriseRegistration.getCurrentStep().GA_LABEL === 'Congratulations_Step' &&
        this.enterpriseRegistration.getPreviousStep().GA_LABEL === 'EmailVerification_Step') ||
      this.enterpriseRegistration.getCurrentStep().GA_LABEL === 'Appointment_Booking_Multiple_Slots'
    );
  }

  get form(): FormGroup {
    return this.enterpriseRegistration.form;
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  get showLoginLink(): boolean {
    return this.enterpriseRegistration.showLoginLink();
  }
}
