interface ApiV2FromAppointment {
  id: number;
  start_time: string;
  office_name: string;
  provider_name: string;
}

export class FromAppointment {
  id: number;
  startTime: string;
  officeName: string;
  providerName: string;

  static fromApiV2({ id, start_time, office_name, provider_name }: ApiV2FromAppointment): FromAppointment {
    const fromAppointment = new FromAppointment();
    fromAppointment.id = id;
    fromAppointment.startTime = start_time;
    fromAppointment.officeName = office_name;
    fromAppointment.providerName = provider_name;

    return fromAppointment;
  }

  toApiV2(): ApiV2FromAppointment {
    return {
      id: this.id,
      start_time: this.startTime,
      office_name: this.officeName,
      provider_name: this.providerName,
    };
  }
}
