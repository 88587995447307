import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import kebabCase from 'lodash-es/kebabCase';

export enum BannerType {
  InfoLight = 'InfoLight',
  SuccessLight = 'SuccessLight',
  WarningLight = 'WarningLight',
  ErrorLight = 'DangerLight',
  Light = 'Light',
  Dark = 'Dark',
}

@Component({
  selector: 'omgui-banner',
  templateUrl: './omgui-banner.component.html',
})
export class OmguiBannerComponent {
  /** Choose the type of banner rendered. ErrorLight is remapped to bootstrap danger-light */
  @Input() set type(type: BannerType) {
    if (type === BannerType.ErrorLight) {
      this.bannerType = 'danger-light';
    } else {
      this.bannerType = kebabCase(type);
    }
  }

  /** Whether or not the banner is dismissible. Defaults to false */
  @Input() dismissible = false;

  /** @ignore */
  bannerType: string;
}

@NgModule({
  declarations: [OmguiBannerComponent],
  imports: [NgbAlertModule, CommonModule],
  exports: [OmguiBannerComponent],
})
export class OmguiBannerModule {}
