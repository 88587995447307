export class DocumentItem {
  id: number;
  title: string;
  key: string;
  bucket: string;
  contentType: string;
  contentLength: number;

  constructor() {}

  static fromApiV2(documentObject) {
    const document = new DocumentItem();
    document.id = documentObject.id;
    document.title = documentObject.title;
    document.key = documentObject.key;
    document.bucket = documentObject.bucket;
    document.contentType = documentObject.content_type;
    document.contentLength = documentObject.content_length;

    return document;
  }

  // TODO: Update when GraphQL camelCase is merged
  static fromGraphql(documentObject) {
    const document = new DocumentItem();
    document.id = documentObject.id;
    document.title = documentObject.title;
    document.key = documentObject.key;
    document.bucket = documentObject.bucket;
    document.contentType = documentObject.content_type;
    document.contentLength = documentObject.content_length;

    return document;
  }
}
