import { Component, OnInit, Input } from '@angular/core';

import { ConfigService } from '@app/core/config.service';

import { FooterAnalyticsService } from './footer-analytics.service';

@Component({
  selector: 'om-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() homePage: boolean;
  currentYear = new Date().getFullYear();

  constructor(public configService: ConfigService, public footerAnalyticsService: FooterAnalyticsService) {}

  ngOnInit() {
    this.footerAnalyticsService.setFlowAndModule(this.homePage);
  }

  trackFooterLink(linkClicked: string): Function {
    return this.footerAnalyticsService.trackFooterLink.bind(this.footerAnalyticsService, linkClicked);
  }
}
