import { Office } from '../shared/office';
import { Provider } from '../shared/provider';
import { PastAppointments_patient_endedAndNotUtilizedAppointments_nodes as PastAppointment } from './appointments/__generated__/PastAppointments';
import { UpcomingAppointments_patient_upcomingAndCurrentAppointments } from './appointments/__generated__/UpcomingAppointments';

export class Appointment {
  id: number;
  differentOnsiteTimezone: boolean;
  officeId: number;
  providerId: number;
  reason: string;
  remote: boolean;
  office: Office;
  provider: Provider;
  startTime: string;
  timezone: string;
  appointmentTypeId: number;
  appointmentTypeName: string;
  duration: number;
  remoteMeetingLink: string;

  static fromApiV2({
    appointment_type_id,
    appointment_type_name,
    display_duration,
    id,
    is_different_onsite_timezone,
    is_remote,
    office_id,
    provider_id,
    reason,
    remote_meeting_link,
    start_at,
    timezone_tzinfo,
  }: Record<string, any>): Appointment {
    const appointment = new Appointment();
    appointment.id = id;
    appointment.differentOnsiteTimezone = is_different_onsite_timezone;
    appointment.officeId = office_id;
    appointment.providerId = provider_id;
    appointment.reason = reason;
    appointment.remote = is_remote;
    appointment.startTime = start_at;
    appointment.appointmentTypeId = appointment_type_id;
    appointment.appointmentTypeName = appointment_type_name;
    appointment.duration = display_duration;
    appointment.remoteMeetingLink = remote_meeting_link;
    appointment.timezone = timezone_tzinfo;

    return appointment;
  }

  static fromGraphQL({ id, provider, startAt }: Record<string, any>): Appointment {
    const appointment = new Appointment();
    appointment.id = id;
    appointment.provider = Provider.fromGraphQL(provider);
    appointment.startTime = startAt;
    return appointment;
  }
}

export type GraphQLAppointment = UpcomingAppointments_patient_upcomingAndCurrentAppointments | PastAppointment;
