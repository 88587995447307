import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { EmailVerificationService } from '@app/shared/email-verification.service';

export interface MixpanelEmailStatuses {
  login_email_status?: string;
  preferred_email_status?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationAnalyticsHelperService {
  private membershipType$: Observable<string>;
  private emailStatuses$: Observable<MixpanelEmailStatuses>;

  constructor(
    private userService: UserService,
    private membershipService: MembershipService,
    private emailVerificationService: EmailVerificationService,
  ) {
    this.emailStatuses$ = combineLatest([
      userService.getUser().pipe(map(user => user.loginEmailIsPreferred())),
      emailVerificationService.getVerificationStatus$().pipe(map(status => status.emailVerified)),
    ]).pipe(
      map(([loginIsMain, emailVerified]) => {
        const status = emailVerified ? 'Verified' : 'Unverified';
        const emailStatus: MixpanelEmailStatuses = loginIsMain // This variable is only for enforcing type checking
          ? { login_email_status: status }
          : { preferred_email_status: status };
        return emailStatus;
      }),
      shareReplay(1),
    );

    this.membershipType$ = membershipService.getMembership().pipe(
      map(membership => (membership.isOMNow() ? 'OM Now' : membership.omMembershipType)),
      shareReplay(1),
    );
  }

  get emailStatuses(): Observable<MixpanelEmailStatuses> {
    return this.emailStatuses$.pipe(take(1));
  }

  get membershipType(): Observable<string> {
    return this.membershipType$.pipe(take(1));
  }
}
