import { Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RoutingGroupOption } from './routing-group-option.interface';

@Component({
  selector: 'om-routing-group',
  templateUrl: './routing-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoutingGroupComponent),
      multi: true,
    },
  ],
})
export class RoutingGroupComponent<T> implements ControlValueAccessor, OnDestroy {
  @HostBinding() class = 'd-flex justify-content-center';
  @Output() change: EventEmitter<T> = new EventEmitter();
  @Input() options: RoutingGroupOption<T>[];
  @Input() className = 'list-group align-self-center col-md-9 pr-0';

  private destroy$ = new Subject<void>();

  formControl: FormControl = new FormControl('');
  optionSelected: boolean;
  onTouched = () => {};

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: (value: T) => void) {
    const onChange = (value: T) => {
      fn(value);
      this.onTouched();
      this.change.emit(value);
      this.optionSelected = true;
    };
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(onChange);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  writeValue(value: T): void {
    this.formControl.setValue(value);
  }
}
