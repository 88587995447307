import { Component, OnInit } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, startWith, take } from 'rxjs/operators';

import {
  MembershipResult,
  MembershipResult_membership_plan_B2bPlan as B2bPlan,
} from '@app/core/__generated__/MembershipResult';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LoginService } from '@app/core/login.service';
import { Membership, MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';

import { ProgramEnrollmentGraphQL } from '../program-enrollment-graphql.service';
import { InviteFriendsModalComponent } from '../ribbon-banner';
import { User } from '../user';

@Component({
  selector: 'om-navbar-container',
  templateUrl: 'navbar-container.component.html',
})
export class NavbarContainerComponent implements OnInit {
  enrolledInMindsetPlus$: Observable<boolean>;
  user$: Observable<User>;
  canRegisterKid$: Observable<boolean>;
  inviteCta$: Observable<string>;
  dataReady$: Observable<boolean>;
  inactiveMembership$: Observable<boolean>;
  canBookVisit$: Observable<boolean>;

  private membership$: Observable<Membership>;

  constructor(
    private loginService: LoginService,
    private modal: NgbModal,
    private featureFlagSelectors: FeatureFlagSelectors,
    private membershipService: MembershipService,
    private userService: UserService,
    private membershipGraphQL: MembershipGraphQL,
    private programEnrollmentGraphQL: ProgramEnrollmentGraphQL,
  ) {}

  ngOnInit() {
    this.user$ = this.userService.getUser().pipe(shareReplay(1));
    this.membership$ = this.membershipService.getMembership().pipe(shareReplay(1));
    this.inactiveMembership$ = this.membership$.pipe(map(membership => !membership.isActive));
    this.canBookVisit$ = this.membership$.pipe(map(membership => membership.canBookVisit));

    this.initCanRegisterKid();

    this.inviteCta$ = this.user$.pipe(
      map(user => {
        if (user.referralDiscount) {
          return `Give ${user.referralDiscount.getDiscountString()} off`;
        }
      }),
      startWith('Invite Friends'),
      shareReplay(1),
    );

    this.enrolledInMindsetPlus$ = combineLatest([
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.BHX_MEMBER_PROGRAM_EXPERIENCE, false),
      this.programEnrollmentGraphQL.enrollmentForProgram$('mindset+'),
    ]).pipe(
      map(([flagEnabled, programEnrollment]) => flagEnabled && !!programEnrollment),
      shareReplay(1),
    );

    this.dataReady$ = combineLatest([
      this.enrolledInMindsetPlus$,
      this.user$,
      this.membership$,
      this.canRegisterKid$,
      this.inviteCta$,
    ]).pipe(
      take(1),
      map(() => true),
    );
  }

  initCanRegisterKid() {
    const isB2bCoreMember$ = this.membershipGraphQL.fetch().pipe(
      map((result: ApolloQueryResult<MembershipResult>) => {
        const { plan, planType } = result.data.membership;
        return !(plan as B2bPlan)?.company?.includesDependent && planType !== MembershipPlan.SELF_PAID;
      }),
    );

    const isAdultConsumerMember$ = combineLatest([this.membership$, this.user$]).pipe(
      map(([membership, user]) => membership.omMembershipType === 'Consumer' && !user.isPediatric()),
    );

    this.canRegisterKid$ = combineLatest([isB2bCoreMember$, isAdultConsumerMember$]).pipe(
      map(([isB2bCore, isAdultConsumer]) => isB2bCore || isAdultConsumer),
      shareReplay(1),
    );
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout();
  }

  openInviteModal() {
    this.modal.open(InviteFriendsModalComponent);
  }
}
