import { Injectable } from '@angular/core';
import map from 'lodash-es/map';
import range from 'lodash-es/range';
import take from 'lodash-es/take';
import moment from 'moment';

export class MonthData {
  name: string;
  monthNumber: string;
}

@Injectable()
export class DateService {
  private readonly monthData;
  private readonly currentYear;
  private readonly currentMonth;

  constructor() {
    this.monthData = this.buildMonthData();
    this.currentYear = moment().year();
    this.currentMonth = moment().month() + 1;
  }

  private buildMonthData(): MonthData[] {
    return map(moment.months(), (month: string, index: number) => {
      const monthNumber = index + 1;
      const monthNumberString = monthNumber < 10 ? `0${monthNumber.toString()}` : monthNumber.toString();
      return { name: month, monthNumber: monthNumberString };
    });
  }

  get months() {
    return this.monthData;
  }

  lastXYears(number: number): number[] {
    return range(this.currentYear, this.currentYear - number);
  }

  pastCurrentMonthsForYear(year: number): MonthData[] {
    if (year < this.currentYear) {
      return this.months;
    }
    return take(this.monthData, this.currentMonth);
  }
}
