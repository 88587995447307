<div
  class="d-flex flex-column fixed-top mobile-nav-container"
  data-cy="hamburger-menu"
  [class.open]="menuOpen[menu.MAIN]"
>
  <nav aria-label="Main Nav" class="navbar full d-flex justify-content-between bg-white shadow-sm">
    <a
      routerLink="/"
      [omTrackLink]="trackHomeLink"
      class="navbar-brand"
      data-cy="om-logo-nav"
      [class.disabled]="inactiveMembership"
    >
      <om-logo></om-logo>
    </a>

    <div class="mr-2">
      <a
        routerLink="/messages"
        [omTrackLink]="trackMessagesLink"
        aria-label="Messages"
        data-cy="messages-icon-nav"
        class="svg-message-container"
        *ngIf="!menuOpen[menu.MAIN]"
      >
        <om-svg-message stroke="currentColor"></om-svg-message>
      </a>

      <button
        class="btn navbar-toggler navbar-side-control"
        type="button"
        (click)="menuOpen[menu.MAIN] ? closeMenus() : toggleMenu(menu.MAIN)"
        [attr.aria-expanded]="menuOpen[menu.MAIN]"
        aria-label="Toggle Menu"
        title="Toggle navigation"
        data-cy="toggle-slide-menu"
      >
        <ng-container *ngIf="menuOpen[menu.MAIN]; then closeButton; else openButton"></ng-container>
      </button>

      <ng-template #openButton>
        <om-svg-hamburger stroke="currentColor" data-cy="open-slide-menu"></om-svg-hamburger>
      </ng-template>

      <ng-template #closeButton>
        <omgui-close-x stroke="currentColor" data-cy="close-slide-menu"></omgui-close-x>
      </ng-template>
    </div>
  </nav>

  <div class="slide-menu" [class.open]="menuOpen[menu.MAIN]">
    <nav aria-label="Expandable Main Menu" class="navbar d-flex">
      <ul class="navbar-nav" (click)="closeMenus()">
        <li>
          <a
            class="dropdown-item book-visit"
            [class.disabled]="!canBookVisit"
            routerLink="/appointments/new"
            [omTrackLink]="trackBookVisitLink"
            data-cy="book-visit-slide-menu"
          >
            Get Care
          </a>
        </li>
        <li><a class="dropdown-item messages" routerLink="/messages" data-cy="messages-slide-menu">Messages</a></li>
        <li>
          <a
            class="dropdown-item tasks"
            routerLink="/tasks"
            [omTrackLink]="trackTasksLink"
            data-cy="care-plan-slide-menu"
          >
            Care Plan
          </a>
        </li>
        <li>
          <a
            class="dropdown-item d-flex justify-content-between align-items-center"
            href="#"
            data-cy="health-record-slide-menu"
            (click)="toggleMenu(menu.HEALTH_RECORD, $event)"
          >
            <div>Health Record</div>
            <om-svg-caret-down stroke="currentColor" [hidden]="menuOpen[menu.HEALTH_RECORD]"></om-svg-caret-down>
            <om-svg-caret-up stroke="currentColor" [hidden]="!menuOpen[menu.HEALTH_RECORD]"></om-svg-caret-up>
          </a>
          <ul class="navbar-nav sub-nav" [ngbCollapse]="!menuOpen[menu.HEALTH_RECORD]">
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/lab-results">
                <om-svg-lab-result
                  color="currentColor"
                  class="nav-icon"
                  data-cy="lab-results-slide-menu"
                ></om-svg-lab-result>
                Lab Results
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/medications">
                <om-svg-medication
                  color="currentColor"
                  class="nav-icon"
                  data-cy="medications-slide-menu"
                ></om-svg-medication>
                Medications
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/vaccines">
                <om-svg-vaccines color="currentColor" class="nav-icon" data-cy="vaccines-slide-menu"></om-svg-vaccines>
                Vaccines
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/vitals">
                <om-svg-vitals color="currentColor" class="nav-icon" data-cy="vitals-slide-menu"></om-svg-vitals>
                Vitals
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a
            class="dropdown-item d-flex justify-content-between align-items-center"
            href="#"
            data-cy="account-slide-menu"
            (click)="toggleMenu(menu.ACCOUNT, $event)"
          >
            <div>Account</div>
            <om-svg-caret-down stroke="currentColor" [hidden]="menuOpen[menu.ACCOUNT]"></om-svg-caret-down>
            <om-svg-caret-up stroke="currentColor" [hidden]="!menuOpen[menu.ACCOUNT]"></om-svg-caret-up>
          </a>
          <ul class="navbar-nav sub-nav" [ngbCollapse]="!menuOpen[menu.ACCOUNT]">
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/profile">
                <om-svg-profile class="nav-icon"></om-svg-profile>
                Profile
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/membership/settings">
                <om-svg-membership-and-billing
                  class="nav-icon"
                  data-cy="membership-billing-slide-menu"
                ></om-svg-membership-and-billing>
                Membership &amp; Billing
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/notification-settings">
                <om-svg-notification-settings
                  class="nav-icon"
                  data-cy="notification-settings-slide-menu"
                ></om-svg-notification-settings>
                Preferences
              </a>
            </li>
            <li *ngIf="recordsDownloadEnabled$ | async">
              <a
                class="dropdown-item inline-icon-with-text"
                routerLink="/account/request-records"
                data-cy="request-records"
              >
                <om-svg-request-records class="nav-icon"></om-svg-request-records>
                Request Records
              </a>
            </li>
            <ng-container *ngIf="!pediatric">
              <li>
                <a
                  class="dropdown-item sub-nav inline-icon-with-text hie-link"
                  routerLink="/account/health-information-exchange"
                >
                  <om-svg-health-information-exchange
                    class="nav-icon"
                    data-cy="health-information-exchange-slide-menu"
                  ></om-svg-health-information-exchange>
                  Health Information Exchange
                </a>
              </li>
            </ng-container>
            <li>
              <a class="dropdown-item sub-nav inline-icon-with-text" routerLink="/account/security">
                <om-svg-security class="nav-icon" data-cy="security-slide-menu"></om-svg-security>
                Account Security
              </a>
            </li>
            <li *ngIf="connectedAccountsActive$ | async">
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/connected-accounts">
                <om-svg-watch class="nav-icon" data-cy="connected-accounts-slide-menu"></om-svg-watch>
                Apps and Devices
              </a>
            </li>
          </ul>
        </li>

        <li class="mobile-app-link" *ngIf="!!mobileAppLink">
          <a class="dropdown-item" target="_blank" [href]="mobileAppLink" (click)="handleAppLinkClick()">
            Get The App
          </a>
        </li>
        <li>
          <a class="dropdown-item" routerLink="/appointments/list" data-cy="appointments-nav">Appointments</a>
          <ng-container *ngIf="!virtual">
            <a class="dropdown-item" routerLink="/account/my-provider" data-cy="provider-nav"> Provider </a>
          </ng-container>
        </li>
        <li>
          <a
            *ngIf="enrolledInMindsetPlus"
            class="dropdown-item sub-nav"
            routerLink="/programs/mindset+"
            data-cy="program-hub-nav"
            >Mindset+</a
          >
        </li>
        <li>
          <a
            *ngIf="canRegisterKid"
            class="dropdown-item sub-nav inline-icon-with-text register-child"
            routerLink="/registration/pediatric"
            [omTrackLink]="trackRegisterChildLink"
            data-cy="consumer-ped-reg-nav"
          >
            <om-svg-family class="nav-icon"></om-svg-family>
            Sign up your kid
          </a>
          <a
            *ngIf="directSignupEligible"
            class="dropdown-item sub-nav inline-icon-with-text"
            [routerLink]="links.referrals"
            [omTrackLink]="trackDirectSignupLink"
            data-cy="family-nav"
          >
            <om-svg-family color="currentColor" class="nav-icon"></om-svg-family>
            Register Family Member
          </a>
        </li>
        <li>
          <a class="dropdown-item sub-nav inline-icon-with-text" (click)="inviteModalClicked.emit()">
            <om-svg-gift-box-icon fill="currentColor" class="nav-icon"></om-svg-gift-box-icon>
            {{ inviteCta }}
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="#" (click)="logoutClicked.emit($event)" data-cy="log-out-slide-menu">
            Log Out
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="overlay" [class.open]="menuOpen[menu.MAIN]" (click)="closeMenus()"></div>
</div>
