import first from 'lodash-es/first';
import keys from 'lodash-es/keys';
import moment from 'moment-timezone';

import { Office } from '../shared/office';
import { Provider } from '../shared/provider';

interface ApiV2AppointmentInventory {
  utc_start_datetime: string;
  timezone_tzinfo: string;
  duration: number;
  id: number;
  date: string;
}

export class AppointmentInventory {
  start_time: moment.Moment;
  timezone: string;
  duration: number;
  date: string;
  id: number;

  static fromApiV2({
    utc_start_datetime,
    timezone_tzinfo,
    duration,
    id,
    date,
  }: ApiV2AppointmentInventory): AppointmentInventory {
    const apptInv = new AppointmentInventory();
    // Sets the appointment start datetime in the timezone of the Appointment's service area
    apptInv.start_time = moment(utc_start_datetime).utc().tz(timezone_tzinfo);
    apptInv.timezone = timezone_tzinfo;
    apptInv.duration = duration;
    apptInv.id = id;
    apptInv.date = date;
    return apptInv;
  }

  static fromGraphQL({
    utcStartDatetime,
    timezoneTzinfo,
    duration,
    date,
    id,
  }: Record<string, any>): AppointmentInventory {
    const apptInv = new AppointmentInventory();

    apptInv.start_time = moment(utcStartDatetime).utc().tz(timezoneTzinfo);
    apptInv.timezone = timezoneTzinfo;
    apptInv.duration = duration;
    apptInv.date = date;
    apptInv.id = id;
    return apptInv;
  }
}

export type DayInventories = Record<string, AppointmentInventory[]>;

export class ProviderInventories {
  provider: Provider;
  office: Office;
  dayInventories: DayInventories;
  nextInventory: AppointmentInventory;
  hasInventories: boolean;

  static fromApiV2({
    office,
    provider,
    next_inventory,
    appointment_inventories_by_date,
  }: Record<string, any>): ProviderInventories {
    const provInv = new ProviderInventories();
    provInv.office = Office.fromApiV2(office);
    provInv.provider = Provider.fromApiV2(provider);
    provInv.nextInventory = AppointmentInventory.fromApiV2(next_inventory);
    provInv.dayInventories = {};
    const inventoriesByDate = appointment_inventories_by_date;
    provInv.hasInventories = inventoriesByDate && keys(inventoriesByDate).length > 0;
    keys(inventoriesByDate).forEach(
      date =>
        (provInv.dayInventories[moment(date).format('YYYY-MM-DD')] = inventoriesByDate[
          date
        ].map((dayInv: ApiV2AppointmentInventory) => AppointmentInventory.fromApiV2(dayInv))),
    );
    return provInv;
  }
}

// These two utility functions were originally part of next-remote-visit component
// and were extracted to share the logic in other areas (i.e. for analytics events)
// returns a YYYY-MM-DD formatted date string
export function firstInventoryDateAvailable(inventories: DayInventories): string {
  return first(Object.keys(inventories));
}

export function getFirstDateInventories(inventories: DayInventories): AppointmentInventory[] {
  const firstDate = firstInventoryDateAvailable(inventories);
  return Object.values(inventories[firstDate]);
}
