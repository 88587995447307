export interface ApiV2AppointmentCancellationReason {
  id: number;
  display_reason: string;
}

export class AppointmentCancellationReason {
  id: number;
  displayReason: string;

  static fromApiV2(response: ApiV2AppointmentCancellationReason): AppointmentCancellationReason {
    const reason = new AppointmentCancellationReason();
    reason.id = response.id;
    reason.displayReason = response.display_reason;
    return reason;
  }

  toApiV2(): ApiV2AppointmentCancellationReason {
    return {
      id: this.id,
      display_reason: this.displayReason,
    };
  }
}
