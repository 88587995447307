import each from 'lodash-es/each';

export class ServiceArea {
  id: number;
  name: string;
  code: string;
  default_office_id: number;
  latitude: number | null;
  longitude: number | null;
  officesCount: number | null;
  registration_hidden: boolean;
  virtual: boolean;

  constructor(attrs = {}) {
    this.setAttributes(attrs);
  }

  static fromApiV2(response): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = response.id;
    serviceArea.name = response.name;
    serviceArea.code = response.code;
    serviceArea.default_office_id = response.default_office_id;
    serviceArea.latitude = response.latitude;
    serviceArea.longitude = response.longitude;
    serviceArea.officesCount = response.offices_count;
    serviceArea.registration_hidden = response.registration_hidden;
    serviceArea.virtual = response.virtual;
    return serviceArea;
  }

  static fromGraphQL(response): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = parseInt('' + response.id, 10);
    serviceArea.name = response.name;
    serviceArea.virtual = response.virtual;

    return serviceArea;
  }

  static fromAppointmentBookingSession(abs): ServiceArea {
    const serviceArea = new ServiceArea();
    serviceArea.id = +abs.id;
    serviceArea.name = abs.name;
    serviceArea.code = abs.code;
    serviceArea.default_office_id = +abs.defaultOffice.id;
    serviceArea.virtual = abs.virtual;

    return serviceArea;
  }

  toApiV2() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      default_office_id: this.default_office_id,
      registration_hidden: this.registration_hidden,
      virtual: this.virtual,
    };
  }

  private setAttributes(attrs): void {
    each(['id', 'name', 'code', 'default_office_id', 'registration_hidden', 'virtual'], attrName => {
      if (attrs[attrName]) {
        this[attrName] = attrs[attrName];
      }
    });
  }
}
