<om-program-promotion-card
  title="Remote Mental Health Visit"
  description="Our primary care providers can help with concerns like stress, anxiety, insomnia, and more. Your care plan could include lifestyle recommendations, medication, or referrals to programs or therapists. Book a remote visit today, from the comfort of your home."
  tag="Now available"
  imageUrl="/assets/images/women-on-phone-with-provider.jpg"
  [canDismiss]="canDismiss"
  (dismiss)="dismissCard($event)"
  (imageClick)="handleClickImage()"
>
  <div data-cy="bhx-mindset-discoverability-card" program-promotion-card-actions>
    <div class="d-flex">
      <a
        class="btn btn-outline-primary mr-2 px-4 promo-card-primary-cta"
        [routerLink]="MENTAL_HEALTH_VISIT_APPT_TYPE_URL"
        [queryParams]="MENTAL_HEALTH_VISIT_QUERY_PARAMS"
        [omTrackLink]="trackMentalHealthPromoCardClicked('Book an appointment')"
        data-cy="primary-cta"
      >
        Book an appointment
      </a>
      <a
        class="btn btn-link mr-2 d-none d-sm-block promo-card-secondary-cta"
        href="https://www.onemedical.com/blog/healthy-living/primary-cares-role-mental-health/"
        [omTrackLink]="trackMentalHealthPromoCardClicked('Learn More')"
        target="_blank"
        data-cy="secondary-cta"
      >
        Learn More
      </a>
    </div>
  </div>
</om-program-promotion-card>
