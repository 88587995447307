import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FlashService {
  private messages: object;
  private _messages$ = new Subject<Object>();
  messages$: Observable<Object> = this._messages$.asObservable();
  flashMessage: EventEmitter<object> = new EventEmitter();

  constructor() {
    this.clearAll();
  }

  addFlashMessage(message, type) {
    this.messages[type].push(message);
    this._messages$.next(this.messages);
  }

  clearMessages(type) {
    this.messages[type] = [];
    this._messages$.next(this.messages);
  }

  clearAll() {
    this.messages = {
      alert: [],
      error: [],
      notice: [],
      success: [],
    };
    this._messages$.next(this.messages);
  }
}
