import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/core/auth.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { BeforeInstallPromptChoices, WindowService } from '@app/utils/window.service';
import { TYPE_SYSTEM_REDESIGN_CLASSNAME, COLOR_SYSTEM_REDESIGN_CLASSNAME } from '@omgui/redesign-constants';

import { AnalyticsService } from './core/analytics.service';

@Component({
  selector: 'om-app',
  templateUrl: './app.component.html',
  providers: [],
})
// Component controller
export class MyOneApp implements OnInit {
  constructor(
    private windowService: WindowService,
    private analyticsService: AnalyticsService,
    private authService: AuthService, // to ensure this is initialized top level
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.windowService.appInstallChoiceStream.subscribe(choiceResult => {
      if (choiceResult.outcome === BeforeInstallPromptChoices.Dismissed) {
        this.analyticsService.appInstallBannerDismissed();
      } else {
        this.analyticsService.appInstallBannerAccepted();
      }
    });

    this.featureFlagSelectors.getFeatureFlag(FeatureFlags.TYPE_SYSTEM_REDESIGN, false).subscribe({
      next: active => {
        if (active) {
          this.windowService.getBody().classList.add(TYPE_SYSTEM_REDESIGN_CLASSNAME);
        } else {
          this.windowService.getBody().classList.remove(TYPE_SYSTEM_REDESIGN_CLASSNAME);
        }
      },
    });

    this.featureFlagSelectors.getFeatureFlag(FeatureFlags.COLOR_SYSTEM_REDESIGN, false).subscribe({
      next: active => {
        if (active) {
          this.windowService.getBody().classList.add(COLOR_SYSTEM_REDESIGN_CLASSNAME);
        } else {
          this.windowService.getBody().classList.remove(COLOR_SYSTEM_REDESIGN_CLASSNAME);
        }
      },
    });
  }
}
