<div
  id="{{ cardId ? 'card-element-' + cardId : 'card-element' }}"
  name="card-element"
  [class.StripeElement--invalid]="externalErrors"
></div>
<div *ngIf="showNicknameField">
  <br />
  <label for="nickname">Nickname for this payment method</label>
  <input type="text" name="nickname" id="nickname-input" [(ngModel)]="nickname" />
</div>
