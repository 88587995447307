import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import {
  CreateHealthInformationReleaseAuthorization as Authorization,
  CreateHealthInformationReleaseAuthorizationVariables as Variables,
} from './__generated__/CreateHealthInformationReleaseAuthorization';

@Injectable()
export class CreateHealthInformationReleaseAuthorizationGraphqlService extends Mutation<Authorization, Variables> {
  document = gql`
    mutation CreateHealthInformationReleaseAuthorization(
      $recipientTelephone: String!
      $signatureText: String!
      $signedAt: DateTime!
      $expiresAt: DateTime!
      $documentVersion: String!
    ) {
      createHealthInformationReleaseAuthorization(
        input: {
          recipientTelephone: $recipientTelephone
          signatureText: $signatureText
          signedAt: $signedAt
          expiresAt: $expiresAt
          documentVersion: $documentVersion
        }
      ) {
        success
        errors
      }
    }
  `;
}
