import { AppointmentBookingStateCache } from './appointment-booking-session-graphql.service';

export class AppointmentBookingSession {
  id: number;
  appointmentBookingStateCache: AppointmentBookingStateCache;

  static fromGraphQL({ appointmentBookingStateCache, id }: AppointmentBookingSession) {
    const abs = new AppointmentBookingSession();
    abs.id = id;
    abs.appointmentBookingStateCache = appointmentBookingStateCache;

    return abs;
  }
}
