import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';
import { OmguiCameraModule } from '@omgui/omgui-camera/omgui-camera.component';
import { OmguiSpinnerModule } from '@omgui/omgui-spinner/omgui-spinner.component';

export class ProfileBubbleStates {
  static DANGER = 'DANGER';
  static PROCESSING = 'PROCESSING';
  static REMOTE = 'REMOTE';
}

/**
 * To generate the fallback image using the profile holder's initials, Profile requires one of the following:
 *  - preferredName + lastName
 *  - firstName + lastName
 *  - displayName
 */
export interface Profile {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  profileImageUrl?: string;
}

@Component({
  selector: 'omgui-profile-bubble',
  templateUrl: './omgui-profile-bubble.component.html',
  styleUrls: ['omgui-profile-bubble-sizes.scss', 'omgui-profile-bubble.component.scss'],
})
export class OmguiProfileBubbleComponent {
  static PATIENT_HIGHEST_COLOR_COMBINATION = 10;
  static DEFAULT_COLOR_COMBINATION = 1;
  static PROVIDER_COLOR_COMBINATION = 11;

  @Input() size = ComponentSize.small;
  @Input() status: string;
  @Input() set colorCombo(colorCombo: number) {
    if (colorCombo == null) {
      this.selectedColorCombo = OmguiProfileBubbleComponent.DEFAULT_COLOR_COMBINATION;
    }

    this.selectedColorCombo = (colorCombo % OmguiProfileBubbleComponent.PATIENT_HIGHEST_COLOR_COMBINATION) + 1;
  }

  @Input() set profile(profile: Profile) {
    if (profile == null) {
      return;
    }

    this.source = profile.profileImageUrl;
    this.profileImageStyle = { 'background-image': `url(${this.source})` };
    this.setDefaultProfileImage(profile);
  }

  initials: string;
  profileBubbleStates = ProfileBubbleStates;
  source: string;
  profileImageStyle: object;
  selectedColorCombo = OmguiProfileBubbleComponent.DEFAULT_COLOR_COMBINATION;
  readonly componentSize = ComponentSize;

  private setDefaultProfileImage(profile: Profile): void {
    if (!!profile.preferredName) {
      this.initials = profile.preferredName[0];

      if (!!profile.lastName) {
        this.initials += profile.lastName[0];
      }
    } else if (!!profile.firstName) {
      this.initials = profile.firstName[0];

      if (!!profile.lastName) {
        this.initials += profile.lastName[0];
      }
    } else if (!!profile.displayName) {
      this.initials = profile.displayName[0];
    }
  }

  fallbackToInitials(e: ErrorEvent) {
    this.source = null;
  }
}

@NgModule({
  declarations: [OmguiProfileBubbleComponent],
  imports: [CommonModule, OmguiCameraModule, OmguiSpinnerModule],
  exports: [OmguiProfileBubbleComponent],
})
export class OmguiProfileBubbleModule {}
