<div class="col-12 row p-0 m-0">
  <div class="col-12 col-xl-6">
    <h2>Verify your email.</h2>
    <div class="mb-4">
      <p class="lh-175"> Enter the code we've just sent to {{ preferredEmail }} to verify your One Medical account. </p>
    </div>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <om-label-wrapper class="" label="" [hasError]="hasError()" [errorMessage]="errorMessage">
          <input
            type="text"
            class="form-control text-field mb-1 col-9"
            placeholder="Code"
            formControlName="verificationCode"
            required="true"
            autofocus
            (keypress)="filterNonNumeric($event)"
            maxlength="6"
          />
        </om-label-wrapper>
      </div>
      <input type="hidden" formControlName="skip" />
      <div class="d-flex mb-4">
        <om-submit-button class="col-4 p-0" label="Continue" [submitting]="submitting"></om-submit-button>
        <button
          type="button"
          class="btn btn-link"
          data-cy="skip-verification"
          aria-label="Skip for now"
          (click)="onSkip()"
        >
          Skip for now
        </button>
      </div>
    </form>
    <form *ngIf="formGroupState" [ngrxFormState]="formGroupState" (submit)="onSubmit()">
      <div class="mb-4">
        <om-label-wrapper class="" label="" [hasError]="hasError()" [errorMessage]="errorMessage">
          <input
            data-cy="verification-code"
            type="text"
            class="form-control text-field mb-1 col-9"
            placeholder="Code"
            [ngrxFormControlState]="formGroupState.controls.verificationCode"
            required="true"
            autofocus
            (keypress)="filterNonNumeric($event)"
            maxlength="6"
          />
        </om-label-wrapper>
      </div>
      <input type="hidden" [ngrxFormControlState]="formGroupState.controls.skip" />
      <div class="d-flex mb-4">
        <om-submit-button class="col-4 p-0" label="Continue" [submitting]="submitting"></om-submit-button>
        <button
          type="button"
          class="btn btn-link"
          data-cy="skip-verification"
          aria-label="Skip for now"
          (click)="onSkip()"
        >
          Skip for now
        </button>
      </div>
    </form>
    <div class="col-12 p-0 lh-175">
      Didn't get the email?
      <a href="#" data-cy="resend-email" aria-label="Resend email" (click)="onResendEmail($event)"> Resend email </a>
      <br />
      Need help? Contact
      <a
        (click)="onEmailTechSupport($event)"
        href="mailto:techsupport@onemedical.com"
        aria-label="Contact technical support"
      >
        techsupport@onemedical.com
      </a>
    </div>
  </div>
  <div class="col-xl-6 d-none d-xl-flex justify-content-center">
    <img class="col-10" src="/assets/images/email-verification.svg" />
  </div>
</div>
