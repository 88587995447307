import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Dict } from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppointmentAnalyticsProperty } from '@app/appointment/appointment-analytics.service';
import { AnalyticsService } from '@app/core/analytics.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags, HomeRedesignVariation } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import {
  EVENT_BANNER_CLICKED,
  EVENT_BOOK_VISIT_CLICKED,
  EVENT_TREAT_ME_NOW_MODAL_CLOSED,
  FLOW_ACCOUNT_UPDATE,
  FLOW_APPOINTMENT_BOOKING,
  FLOW_HOME_PAGE_NAVIGATION,
  MANAGE_PRESCRIPTIONS_CLICKED,
  MOBILE_ENGAGEMENT,
  MOBILE_ENGAGEMENT_MODAL_CLOSED,
  MODULE_HOME_PAGE,
  MODULE_PCP_SELECTION_PAGE,
  MP_EVENT_EMAIL_VERIFICATION_SENT,
  MP_EVENT_PAGE_VIEWED,
  PRESCRIPTION_MANAGEMENT,
  PRESCRIPTION_RENEWAL_PAGE,
  SEND_DOWNLOAD_LINK_CLICKED,
  SUBMODULE_COVID_RESOURCES,
  SUBMODULE_EMAIL_VERIFICATION_CAROUSEL_CARD,
  TREAT_ME_NOW_CLICKED,
  VIDEO_CHAT_CLICKED,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { Appointment_appointment } from '@app/home/__generated__/Appointment';
import { Reminders_patient_notifications_notifiableType_reminderActions } from '@app/home/__generated__/Reminders';

import { State } from '../app.reducer';

export interface TrackPageViewProps {
  source?: string;
  is_modal_shown: boolean;
  modal_type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HomePageAnalyticsService extends AnalyticsService {
  protected readonly module = MODULE_HOME_PAGE;
  private readonly cardSubmodule = 'Card';
  private readonly coronavirusCardSubmodule = 'Coronavirus Card';
  private readonly heroSubmodule = 'Hero';
  private readonly cardDisplayed = 'Card Displayed';
  private readonly cardDismissed = 'Card Dismissed';
  private readonly onboardingCarouselDisplayed = 'Onboarding Carousel Displayed';
  private readonly onboardingCarouselCardClicked = 'Onboarding Carousel Card Clicked';

  constructor(
    private featureFlagSelectors: FeatureFlagSelectors,
    protected mixpanelService: MixpanelService,
    protected store: Store<State>,
    protected launchDarklyService: LaunchDarklyService,
    protected router?: Router,
  ) {
    super(mixpanelService, store, launchDarklyService, router);
  }

  getModule(): string {
    return this.module;
  }

  trackListCardLink(eventName: string) {
    return this.trackWithDefaultProperties(eventName, {
      flow: 'Member Request',
      submodule: this.cardSubmodule,
    });
  }

  trackChoosePcpLink() {
    return this.trackWithDefaultProperties('Page Viewed', {
      flow: 'PCP Selection',
      module: MODULE_PCP_SELECTION_PAGE,
      source: 'MyOne Homepage',
    });
  }

  trackEditPcpLink() {
    return this.trackWithDefaultProperties('Edit PCP Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
      submodule: this.cardSubmodule,
    });
  }

  trackHeroBookVisitLink() {
    return super.trackBookVisitLink({
      module: this.module,
      submodule: this.heroSubmodule,
    });
  }

  trackHealthRecordLink() {
    return super.trackHealthRecordLink({
      module: this.module,
      submodule: this.cardSubmodule,
    });
  }

  trackPreferredPharmacyLink(querySelector: string) {
    return this.trackWithDefaultProperties('Preferred Pharmacy Clicked', {
      flow: 'Prescription Renewal',
      submodule: this.cardSubmodule,
    });
  }

  trackRequestPrescriptionRenewalLink() {
    return this.trackWithDefaultProperties('Request Prescription Renewal Clicked', {
      flow: 'Prescription Renewal',
      submodule: this.cardSubmodule,
    });
  }

  trackLegacyHomePageViewed(props: TrackPageViewProps) {
    const homeScreenRedesignExperimentProperties = {
      experiment_name: FeatureFlags.HOME_SCREEN_REDESIGN,
      experiment_variant_name: HomeRedesignVariation.OFF,
    };

    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      ...props,
      ...homeScreenRedesignExperimentProperties,
    });
  }

  trackHomePageViewed(): void {
    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.HOME_SCREEN_REDESIGN)
      .pipe(take(1))
      .subscribe({
        next: variation => {
          this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
            flow: FLOW_HOME_PAGE_NAVIGATION,
            experiment_name: FeatureFlags.HOME_SCREEN_REDESIGN,
            experiment_variant_name: variation,
          });
        },
      });
  }

  trackMentalHealthPromotionCardDisplayed() {
    return this.trackWithDefaultProperties('Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'MH PCP Card',
    });
  }

  trackMentalHealthPromotionCardClicked({
    clickType,
    experimentName,
    experimentVariation,
  }: {
    clickType: string;
    experimentName?: string;
    experimentVariation?: boolean;
  }) {
    return this.trackWithDefaultProperties('MH PCP Card Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'MH PCP Card',
      click_type: clickType,
      ...(experimentName !== undefined && { experiment_name: experimentName }),
      ...(experimentVariation !== undefined && { experiment_variation: experimentVariation }),
    });
  }

  trackMindsetProgramPromotionCardDisplayed() {
    return this.trackWithDefaultProperties('Enterprise Mindset Promo Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
    });
  }

  trackMindsetProgramPromotionCardClicked({ clickType }: { clickType: string }) {
    return this.trackWithDefaultProperties('Enterprise Mindset Promo Card Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      click_type: clickType,
    });
  }

  trackStressManagementCardDisplayed() {
    return this.trackWithDefaultProperties('Stress & Anxiety Program Promo Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'Stress & Anxiety Program Promo Card',
    });
  }

  trackStressManagementCardClicked({ clickType }: { clickType: string }) {
    return this.trackWithDefaultProperties('Stress & Anxiety Program Promo Card Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'Stress & Anxiety Program Promo Card',
      click_type: clickType,
    });
  }

  trackDownloadAppLinkSent() {
    this.trackWithDefaultProperties('App Link Sent', {
      submodule: this.cardSubmodule,
      flow: MOBILE_ENGAGEMENT,
    });
  }

  trackCoronavirusDownloadAppLinkSent() {
    this.trackWithDefaultProperties('App Link Sent', {
      submodule: this.coronavirusCardSubmodule,
      flow: MOBILE_ENGAGEMENT,
      source: 'Coronavirus Card',
    });
  }

  sharePcpStarted() {
    this.trackWithDefaultProperties('Share PCP Started', {
      flow: 'Share PCP',
      module: 'Share PCP Modal',
    });
  }

  sharePcpSubmitted() {
    this.trackWithDefaultProperties('Share PCP Submitted', {
      flow: 'Share PCP',
      module: 'Share PCP Modal',
    });
  }

  trackReminderCardDisplayed(appointment: Appointment_appointment) {
    this.trackWithDefaultProperties('Card Displayed', {
      appointment_id: appointment.id,
      appointment_type: appointment.appointmentType.displayName,
      flow: 'Appointment Booking',
      submodule: 'Upcoming Appointment Card',
    });
  }

  trackReminderActionClicked(
    action: Reminders_patient_notifications_notifiableType_reminderActions,
    appointment: Appointment_appointment,
  ) {
    this.trackWithDefaultProperties(action.event, {
      appointment_id: appointment.id,
      appointment_type: appointment.appointmentType.displayName,
      flow: 'Appointment Booking',
      submodule: 'Upcoming Appointment Card',
    });
  }

  trackSleepCardDisplayed() {
    const properties = {
      flow: 'Appointment Booking',
      submodule: 'Sleep Card',
    };
    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackSleepCardDismissed() {
    const properties = {
      flow: 'Appointment Booking',
      submodule: 'Sleep Card',
    };
    this.trackWithDefaultProperties(this.cardDismissed, properties);
  }

  trackBookPhysicalSleepCardClicked() {
    return this.trackWithDefaultProperties('Book Physical on Sleep Card Clicked', {
      flow: 'Appointment Booking',
      submodule: 'Sleep Card',
      source: 'Sleep Card',
    });
  }

  trackDownloadAppCardDisplayed() {
    const properties = {
      flow: MOBILE_ENGAGEMENT,
      submodule: 'App Download Card',
    };
    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackDownloadAppCardDismissed() {
    const properties = {
      flow: MOBILE_ENGAGEMENT,
      submodule: 'App Download Card',
    };
    this.trackWithDefaultProperties(this.cardDismissed, properties);
  }

  trackReturnToWorkDailyCheckInDisplayed() {
    const properties = {
      flow: 'Healthy Together Check-In Survey',
      submodule: 'Healthy Together Check-In Card',
    };
    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackReturnToWorkDailyCheckInClicked() {
    return this.trackWithDefaultProperties('Survey Loaded', {
      flow: 'Healthy Together Check-In Survey',
      source: 'Healthy Together Check-In Card',
      survey_id: 27,
    });
  }

  trackAfterVisitSummariesDownloadAppModal() {
    const properties = {
      flow: 'After Visit Summaries',
      submodule: 'App Download Card',
    };

    this.trackWithDefaultProperties(this.cardDisplayed, properties);
  }

  trackOnboardingCarouselDisplayed() {
    const properties = {
      flow: 'Onboarding',
    };
    this.trackWithDefaultProperties(this.onboardingCarouselDisplayed, properties);
  }

  trackOnboardingCarouselClicked(submodule: string) {
    const properties = {
      flow: 'Onboarding',
      submodule,
    };
    this.trackWithDefaultProperties(this.onboardingCarouselCardClicked, properties);
  }

  trackEmailVerificationCodeSent() {
    const properties = {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: SUBMODULE_EMAIL_VERIFICATION_CAROUSEL_CARD,
    };
    this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFICATION_SENT, properties);
  }

  trackTreatMeNowModalClosed() {
    this.trackWithDefaultProperties(EVENT_TREAT_ME_NOW_MODAL_CLOSED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
    });
  }

  trackCovidBannerClicked() {
    this.trackWithDefaultProperties(EVENT_BANNER_CLICKED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: SUBMODULE_COVID_RESOURCES,
    });
  }

  trackBookVisitClicked(submodule: string): Observable<void> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: AppointmentAnalyticsProperty.BookingPageModule,
      submodule: submodule,
    });
  }

  trackManagePrescriptionsClicked(): Observable<void> {
    return this.trackWithDefaultProperties(MANAGE_PRESCRIPTIONS_CLICKED, {
      flow: PRESCRIPTION_MANAGEMENT,
      module: PRESCRIPTION_RENEWAL_PAGE,
    });
  }

  trackTreatMeNowClicked(): Observable<void> {
    return this.trackWithDefaultProperties(TREAT_ME_NOW_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  trackVideoChatClicked(): Observable<void> {
    return this.trackWithDefaultProperties(VIDEO_CHAT_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  trackSendDownloadLinkClicked(): Observable<void> {
    return this.trackWithDefaultProperties(SEND_DOWNLOAD_LINK_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  trackDownloadAppModalClosed(): Observable<void> {
    return this.trackWithDefaultProperties(MOBILE_ENGAGEMENT_MODAL_CLOSED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  protected trackWithDefaultProperties(eventName: string, properties?: Dict): Observable<any> {
    if (!properties.module) {
      properties.module = this.module;
    }
    return super.trackWithDefaultProperties(eventName, properties);
  }
}
