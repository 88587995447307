import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookVisitCtaService {
  private _cta$ = new ReplaySubject<string>(1);
  readonly cta$ = this._cta$.asObservable();

  constructor() {
    this._cta$.next('Get Care');
  }
}
