import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment, { Moment } from 'moment';

type CustomValidator = (now: Moment, date: Moment) => ValidationErrors | null;

export function dateValidator(control: AbstractControl) {
  return moment(control.value, 'YYYY-MM-DD', true).isValid() ? null : { date: control.value };
}

export function dateComparisonValidatorCreator(customValidator: CustomValidator): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const val = control.value?.hasOwnProperty('date') ? control.value.date : control.value;
    const date = moment(val, 'YYYY-MM-DD');
    if (date.isValid()) {
      const now = moment().startOf('day');
      return customValidator(now, date);
    } else {
      return { dateInvalid: { valid: false } };
    }
  };
}

export function getDateInPastValidator(): ValidatorFn {
  const validationFunction = (now: Moment, date: Moment) => {
    const dateDiff = date.diff(now, 'days');
    if (dateDiff > 0) {
      return { dateInPast: { valid: false } };
    } else {
      return null;
    }
  };

  return dateComparisonValidatorCreator(validationFunction);
}

export function getDateInFutureValidator(includeNow = false): ValidatorFn {
  const validationFunction = (now: Moment, date: Moment) => {
    const dateDiff = date.diff(now, 'days');
    if (includeNow && dateDiff < 0) {
      return { dateInFuture: { valid: false } };
    } else if (!includeNow && dateDiff <= 0) {
      return { dateInFuture: { valid: false } };
    } else {
      return null;
    }
  };

  return dateComparisonValidatorCreator(validationFunction);
}

export function maximumAge(age: number): ValidatorFn {
  const validationFunction = (now: Moment, date: Moment) => {
    const yearsAgo = now.diff(date, 'years');
    if (yearsAgo > age) {
      return {
        age: {
          valid: false,
          requiredAge: age,
        },
      };
    } else {
      return null;
    }
  };
  return dateComparisonValidatorCreator(validationFunction);
}

export function minimumAge(minAge: number): ValidatorFn {
  const validationFunction = (now: Moment, date: Moment) => {
    const age = now.diff(date, 'years');
    if (age < minAge) {
      return {
        age: {
          valid: false,
          requiredAge: minAge,
        },
      };
    } else {
      return null;
    }
  };
  return dateComparisonValidatorCreator(validationFunction);
}
