<div
  class="card"
  [class.hide-border-bottom]="bottomBorderHidden"
  @enter
  [@cardContent]="useLoadingSkeleton ? successState : pageState"
>
  <ng-container *ngIf="pageState | isSuccessState">
    <ng-container *ngIf="isEmptyState(); then emptyView; else cardContent"> </ng-container>

    <ng-template #cardContent>
      <ng-container
        *ngTemplateOutlet="sections.length == 0 ? sectionView : sectionsView; context: { rows: rows, header: header }"
      >
      </ng-container>
    </ng-template>

    <ng-template #sectionView let-rows="rows" let-header="header">
      <div class="card-header d-flex justify-content-between" *ngIf="header && rows.length" @enter>
        <ng-template [ngTemplateOutlet]="header.templateRef"></ng-template>
        <ng-container *ngIf="headerLink">
          <small>
            <a href="#" (click)="headerLink.handleClick($event)"
              ><ng-template [ngTemplateOutlet]="headerLink.templateRef"></ng-template
            ></a>
          </small>
        </ng-container>
      </div>

      <ng-container *ngTemplateOutlet="rowView; context: { rows: rows }"></ng-container>
    </ng-template>

    <ng-template #sectionsView>
      <ng-container *ngFor="let section of sections">
        <ng-container *ngTemplateOutlet="sectionView; context: { rows: section.rows, header: section.header }">
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-template #rowView let-rows="rows">
      <div class="list-group list-group-flush" *ngIf="rows?.length" @enter>
        <ng-container *ngFor="let row of rows">
          <ng-template #rowContent>
            <ng-container *ngIf="row.templateRef">
              <ng-container *ngTemplateOutlet="row.templateRef"></ng-container>
            </ng-container>
          </ng-template>

          <ng-container *ngIf="row.link || row.useAction; then linkRow; else textRow"></ng-container>

          <ng-template #linkRow>
            <a
              *ngIf="row.link; else actionRow"
              [routerLink]="row.link"
              class="list-group-item list-group-item-action"
              data-cy="card-list-row"
            >
              <ng-container *ngTemplateOutlet="rowContent"></ng-container>
            </a>
          </ng-template>

          <ng-template #actionRow>
            <a
              href="#"
              (click)="row.handleClick($event)"
              class="list-group-item list-group-item-action"
              data-cy="card-list-row"
            >
              <ng-container *ngTemplateOutlet="rowContent"></ng-container>
            </a>
          </ng-template>

          <ng-template #textRow>
            <div class="list-group-item" data-cy="card-list-row">
              <ng-container *ngTemplateOutlet="rowContent"></ng-container>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #emptyView>
      <ng-container *ngIf="emptyState">
        <div
          class="card-header"
          data-cy="empty-state-header"
          *ngIf="emptyState.header || header as emptyStateHeader"
          @enter
        >
          <ng-template [ngTemplateOutlet]="emptyStateHeader.templateRef"></ng-template>
        </div>

        <div class="card-body text-center" @enter>
          <ng-template [ngTemplateOutlet]="emptyState.body.templateRef"></ng-template>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="(pageState | isLoadingState) && useLoadingSkeleton">
    <div class="card-header" *ngIf="header" @enter>
      <ng-template [ngTemplateOutlet]="header.templateRef"></ng-template>
    </div>
    <div class="list-group list-group-flush">
      <div class="list-group-item">
        <omgui-summary-row [pageState]="pageState"></omgui-summary-row>
      </div>
      <div class="list-group-item">
        <omgui-summary-row [pageState]="pageState"></omgui-summary-row>
      </div>
      <div class="list-group-item">
        <omgui-summary-row [pageState]="pageState"></omgui-summary-row>
      </div>
    </div>
  </ng-container>
</div>
