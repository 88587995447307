import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';

import { UserService } from '../../core/user.service';
import { ServiceArea } from '../service-area';
import { ServiceAreaService } from '../service-area.service';

@Component({
  selector: 'om-service-area-select',
  templateUrl: './service-area-select.component.html',
})
export class ServiceAreaSelectComponent implements OnInit {
  @Input() additionalClasses: String;
  @Input() selectedServiceArea: ServiceArea;
  @Input() allowVirtualServiceAreas = true;
  @Input() b2bId = null;

  @Output() selectedServiceAreaChanged = new EventEmitter<ServiceArea>();

  defaultSelection: ServiceArea;
  serviceAreas$: Observable<ServiceArea[]>;

  constructor(
    public serviceAreaService: ServiceAreaService,
    private userService: UserService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.serviceAreas$ = this.allowVirtualServiceAreas
      ? this.serviceAreaService.allServiceAreaOptions$
      : this.serviceAreaService.nonVirtualServiceAreaOptions$;

    if (this.authService.isLoggedIn()) {
      combineLatest([this.userService.user$, this.serviceAreas$])
        .pipe(first())
        .subscribe(([user, serviceAreas]) => {
          let initialSelectedServiceArea: ServiceArea;
          if (this.selectedServiceArea != null) {
            initialSelectedServiceArea = serviceAreas.find(sa => sa.id === this.selectedServiceArea.id);
          } else if (user != null && user.serviceArea != null) {
            initialSelectedServiceArea = serviceAreas.find(sa => sa.id === user.serviceArea.id);
          }

          this.defaultSelection = initialSelectedServiceArea || serviceAreas[0];
          this.selectedServiceAreaChanged.emit(this.defaultSelection);
        });
      this.userService.getUser();
    } else {
      this.serviceAreas$.pipe(first()).subscribe(serviceAreas => {
        this.defaultSelection = serviceAreas[0];
        this.selectedServiceAreaChanged.emit(this.defaultSelection);
      });
    }
    this.serviceAreaService.getServiceAreas(this.b2bId);
  }

  setSelectedServiceArea(serviceArea: ServiceArea) {
    // In cypress, sometimes an undefined service area is selected somehow, which should not be possible
    if (serviceArea) {
      this.selectedServiceAreaChanged.emit(serviceArea);
    }
  }
}
