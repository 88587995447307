import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { GraphqlService } from '@app/core/graphql.service';
import { AdministratorLegalDocGraphQL } from '@app/core/legal-documents';
import { FlashService } from '@app/shared/flash.service';
import { User } from '@app/shared/user';

import { TermsOfService } from './legal-documents/terms-of-service';

export interface Signer {
  type: string;
  id: number;
}

export interface DocumentSigner {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  type: string;
}

/**
 * @deprecated Use {LegalDocumentsService}.
 */

@Injectable()
export class TosService {
  private _tos$ = new Subject<TermsOfService[]>();
  tos$: Observable<TermsOfService[]> = this._tos$.asObservable();

  constructor(
    private flashService: FlashService,
    private graphQlService: GraphqlService,
    private apiService: ApiService,
    private administratorTOSGraphQL: AdministratorLegalDocGraphQL,
  ) {}

  getTos() {
    this.administratorTOSGraphQL.fetch().subscribe({
      next: result => {
        const docsData = result.data.legalDocuments;
        const docs = docsData.map(doc => TermsOfService.fromGraphQL(doc));
        this._tos$.next(docs);
      },
      error: () => {
        this.flashService.addFlashMessage(
          // tododo: add logout link? allow flash service to take html? pull out errors/messages into a yaml file?
          "We've encountered an issue submitting your request. Please log out and try again.",
          'error',
        );
      },
    });
  }

  signDocument(doc: TermsOfService) {
    const query = this.docSignatureQuery();
    const vars = {
      type: doc.type,
    };
    return this.graphQlService.postQuery(query, vars, true);
  }

  private docSignatureQuery() {
    return `SignLegalDocument($type:String!) {
      sign_legal_document(input:{type: $type}) {
        legal_document {
          type
          signed
        }
      }
    }`;
  }

  signForBeneficiaryWhenLoggedInAsAdministrator(doc: TermsOfService, beneficiary: User) {
    return this.apiService.post(`/api/v2/patient/administrator/beneficiaries/${beneficiary.id}/tos_signatures.json`, {
      tos: { type: doc.type, version: doc.version },
      tos_signature: { user_id: beneficiary.id },
    });
  }

  signForBeneficiaryWhenLoggedInAsBeneficiary(doc: TermsOfService, signer: Signer) {
    return this.apiService.post('/api/v2/patient/beneficiary/tos_signatures.json', {
      tos: { type: doc.type, version: doc.version },
      tos_signature: { signer_id: signer.id, signer_type: signer.type },
    });
  }
}
