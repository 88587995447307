import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
  MembershipResult,
  MembershipResult_membership as Membership,
  MembershipResult_membership_plan_B2bPlan as B2bPlan,
} from '@app/core/__generated__/MembershipResult';
import { AuthService } from '@app/core/auth.service';
import { MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { UserService } from '@app/core/user.service';
import { EnterpriseRegistration } from '@app/registration/enterprise';
import { FeatureFlagService } from '@app/shared';
import { Features } from '@app/shared/active-feature-flag-collection';
import { FeatureFlagByB2bIDGraphQL } from '@app/shared/feature-flag-graphql.service';
import { User } from '@app/shared/user';

@Injectable()
export class PediatricMembershipTypeService {
  constructor(
    private enterpriseRegistration: EnterpriseRegistration,
    private authService: AuthService,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    private membershipGraphQL: MembershipGraphQL,
    private featureFlagByB2bIDGraphQL: FeatureFlagByB2bIDGraphQL,
    private userService: UserService,
  ) {}

  get isEnterprise$(): Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      return this.isMembershipCorePlusEnterprise();
    } else if (this.enterpriseRegistration.b2bCompanyId) {
      return this.isEnterpriseByB2bCompanyId();
    } else {
      return observableOf(false);
    }
  }

  isCorePlusEnterprise(membership: Membership): boolean {
    return membership.planType === MembershipPlan.B2B && (membership.plan as B2bPlan).company.includesDependent;
  }

  private isEnterpriseByB2bCompanyId() {
    const b2bId = this.enterpriseRegistration.b2bCompanyId;
    return this.featureFlagByB2bIDGraphQL
      .fetch({ id: b2bId, name: Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION })
      .pipe(
        take(1),
        map(result => !result.data.featureFlag.enabledForCompany && this.b2bCompanyIncludesDependent),
      );
  }

  private isMembershipCorePlusEnterprise(): Observable<boolean> {
    const pediatricEnabled$ = this.featureFlagService
      .featureActive$(Features.DISABLE_ENTERPRISE_PEDS_REGISTRATION)
      .pipe(map(disabled => !disabled));
    this.userService.getUser();
    const user$ = this.userService.user$;

    return combineLatest([pediatricEnabled$, user$, this.membershipGraphQL.fetch()]).pipe(
      take(1),
      map(
        ([pediatricFeatureEnabled, user, membershipResponse]: [boolean, User, ApolloQueryResult<MembershipResult>]) => {
          const { membership } = membershipResponse.data;
          const { whitelistedEmployee } = user;

          if (whitelistedEmployee) {
            return this.isCorePlusEnterprise(membership);
          }

          return (
            pediatricFeatureEnabled &&
            this.isCorePlusEnterprise(membership) &&
            (membership.plan as B2bPlan).hasActiveDiscountTypes
          );
        },
      ),
    );
  }

  private get b2bCompanyIncludesDependent() {
    return this.enterpriseRegistration.b2bCompany && this.enterpriseRegistration.b2bCompany.includesDependent;
  }
}
