<div class="row p-0 mx-0 mb-4 mb-md-5 value-prop">
  <div class="col-12 col-md-6 p-0 order-1 order-md-0">
    <div class="col-12 col-md-11 col-xl-10 float-right">
      <h1 class="mb-3 mt-4 mt-lg-5 mb-lg-4" data-cy="welcome-name">Welcome, {{ welcomeName }}!</h1>
      <p>We're a modern approach to primary care – and your company's sponsoring the membership fee (normally $199).</p>
      <div class="benefits-list mb-4">
        <div class="mb-2">
          <om-svg-checkmark class="mr-2" color="#005450"></om-svg-checkmark>
          <span>Book same or next-day appointments via app</span>
        </div>
        <div class="mb-2">
          <om-svg-checkmark class="mr-2" color="#005450"></om-svg-checkmark>
          <span>Get 24/7 on-demand care with video chat</span>
        </div>
        <div class="mb-2">
          <om-svg-checkmark class="mr-2" color="#005450"></om-svg-checkmark>
          <span>Digitally request prescription renewals</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-12 p-0 m-0 order-0 order-md-1">
    <img class="office-img w-100" src="/assets/images/patient-front-desk.png" alt="patient at the front desk" />
  </div>
</div>
<div class="col-12 col-lg-8 offset-lg-2">
  <h3 class="mb-4 text-center">Confirm your information to start using One Medical</h3>
  <form class="col-12 col-sm-8 offset-sm-2 p-0" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 col-lg-6 mb-2">
        <om-label-wrapper class="" label="First name">
          <input
            #firstName
            type="text"
            class="form-control text-field"
            placeholder="Enter first name"
            formControlName="firstName"
            required="true"
            autofocus
          />
        </om-label-wrapper>
      </div>
      <div class="col-12 col-lg-6 mb-2">
        <om-label-wrapper class="" label="Last name">
          <input
            type="text"
            class="form-control text-field"
            placeholder="Enter last name"
            required="true"
            formControlName="lastName"
          />
        </om-label-wrapper>
      </div>
      <a
        class="col-12 mb-3"
        *ngIf="!showPreferredName"
        href=""
        (click)="$event.preventDefault(); showPreferredName = true"
      >
        Add a preferred name (optional)
      </a>
      <div *ngIf="showPreferredName" class="col-12 col-lg-6 mb-3">
        <om-label-wrapper class="" label="Preferred name">
          <input
            type="text"
            class="form-control text-field"
            placeholder="Enter preferred name"
            formControlName="preferredName"
          />
        </om-label-wrapper>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3"
        ><om-address-input #address [address]="form.get('address')" [prefilledRegistration]="true"></om-address-input
      ></div>
    </div>
    <div class="row">
      <div class="col-12">
        <om-label-wrapper class="" label="Phone number">
          <om-phone-number-input #phoneNumber formControlName="phoneNumber"></om-phone-number-input>
        </om-label-wrapper>
      </div>
    </div>
    <div class="row pt-1 mb-5">
      <div class="col-12 d-flex">
        <div>
          <om-checkbox formControlName="textAppToPhone"></om-checkbox>
        </div>
        <div>
          <p class="mb-0 font-weight-light">Text me a link to download the app</p>
          <small class="text-muted">Get 24/7 care over video chat and messages at no cost</small>
        </div>
      </div>
    </div>
    <div><om-submit-button class="col-12" label="Next" [submitting]="submitting"></om-submit-button></div>
  </form>
</div>
