import includes from 'lodash-es/includes';

export enum Features {
  APPOINTMENT_TYPE_HEADER_CONTENT_WEB = 'appointment_type_header_content_web',
  BHX_SHIFT_HOMESCREEN_PROMO = 'shift-homescreen-promo',
  BHX_PCP_MENTAL_HEALTH_VISIT_HOME_SCREEN_CARD = 'pcp-mental-health-visit-home-screen-card',
  CONNECTED_DEVICES_FITBIT = 'connected-devices-fitbit',
  CORONAVIRUS_BANNER_ENABLED = 'coronavirus_banner_enabled',
  CORONAVIRUS_CARD_ENABLED = 'coronavirus_card_enabled',
  DISABLE_ENTERPRISE_PEDS_REGISTRATION = 'disable_enterprise_peds_registration',
  ACCOUNT_SETTINGS_CONNECTED_DEVICES = 'account-settings-connected-devices',
  INSURANCE_VERIFY_OR_SELF_PAY = 'insurance_verify_or_self_pay',
  PEDIATRIC_CONSUMER_REGISTRATION_PROMOTION = 'pediatric_consumer_registration_promotion',
  SHOW_VIRTUAL_DESCRIPTION_REGISTRATION = 'show_virtual_description_registration',
  VIRTUAL_CARE_EMAIL_VERIFICATION_WEB = 'virtual_care_email_verification_web',
  NEW_SERVICES_MEMBER_APPOINTMENT_SERIES_BOOKING = 'member-appointment-series-booking',
}
export class ActiveFeatureFlagCollection {
  activeFlags: string[];

  constructor(flags: string[]) {
    this.activeFlags = flags;
  }

  static fromApiV2(res: string[]): ActiveFeatureFlagCollection {
    return new ActiveFeatureFlagCollection(res);
  }

  isFeatureActive(feature_name: string): boolean {
    return includes(this.activeFlags, feature_name);
  }
}
