import { Component, HostBinding, Input } from '@angular/core';

import { RoutingGroupOption } from './routing-group-option.interface';

@Component({
  selector: 'om-routing-group-option',
  templateUrl: './routing-group-option.component.html',
  styleUrls: ['./routing-group-option.component.scss'],
})
export class RoutingGroupOptionComponent<T> {
  dataCy: string;
  badgeText: string;
  header: string;
  subheader: string;
  description: string;
  iconUrl: string;

  @Input() set option(option: RoutingGroupOption<T>) {
    this.dataCy = `${option.value}-group-option`;
    this.badgeText = option.badgeText;
    this.header = option.header;
    this.subheader = option.subheader;
    this.description = option.description;
    this.iconUrl = option.iconUrl;
  }

  @HostBinding() class = 'list-group-item list-group-item-action';
  @HostBinding('class.selected') @Input() selected = false;
}
