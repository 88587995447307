<div>
  <ng-container *ngIf="!(hideNavigation$ | async)">
    <ng-container *ngIf="showRefactor$ | async; else legacyNavbar">
      <om-navbar-container data-cy="navbar"></om-navbar-container>
    </ng-container>
  </ng-container>
  <om-expired-membership-banner></om-expired-membership-banner>
  <om-flash></om-flash>
  <om-toast></om-toast>
  <ngb-alert class="text-center" type="success" *ngIf="notice && !closed" (close)="closed = true">{{
    notice
  }}</ngb-alert>
</div>

<div class="flex-grow-1"><router-outlet></router-outlet></div>

<om-footer *ngIf="!shouldHideFooter" [homePage]="onHomePage" data-cy="footer"></om-footer>

<ng-template #legacyNavbar>
  <om-navbar data-cy="navbar"></om-navbar>
</ng-template>
