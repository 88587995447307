<div *ngIf="!createdPatient" class="referral mb-5">
  <div class="container mt-lg-10 mt-2">
    <form
      class="col-12 col-lg-8 offset-lg-2 col-md-10 offset-md-1 px-lg-5 d-flex flex-column p-0"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
    >
      <div class="row">
        <h2 class="text-center mobile-h2 col-12 my-3"> Create a free account for your family </h2>

        <p class="text-center col-12 mb-4 mb-lg-5 direct-signup-subtitle">
          Complete this form to register your spouse or dependents aged 18+.
        </p>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="" label="First name">
            <input
              #firstName
              data-cy="firstName"
              type="text"
              class="form-control text-field"
              placeholder="Enter first name"
              formControlName="firstName"
              required="true"
              autofocus
            />
          </om-label-wrapper>
        </div>

        <div class="col-12 col-lg-6 mb-2">
          <om-label-wrapper class="" label="Last name">
            <input
              type="text"
              data-cy="lastName"
              class="form-control text-field"
              placeholder="Enter last name"
              required="true"
              formControlName="lastName"
            />
          </om-label-wrapper>
        </div>

        <a
          class="col-12 mb-3"
          *ngIf="!showPreferredName"
          href=""
          (click)="$event.preventDefault(); showPreferredName = true"
          data-cy="addPreferredName"
        >
          Add a preferred name (optional)
        </a>

        <div *ngIf="showPreferredName" class="col-12 col-lg-6 mb-3">
          <om-label-wrapper class="" label="Preferred name">
            <input
              type="text"
              class="form-control text-field"
              placeholder="Enter preferred name"
              formControlName="preferredName"
              data-cy="preferredName"
            />
          </om-label-wrapper>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <om-label-wrapper class="d-block" label="Email Address">
            <om-email-input #email formControlName="email" placeholder="Enter their email address"></om-email-input>
          </om-label-wrapper>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <om-label-wrapper class="" label="Phone number">
            <om-phone-number-input #phoneNumber formControlName="phoneNumber"></om-phone-number-input>
          </om-label-wrapper>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <om-label-wrapper label="Date of birth" class="">
            <om-date-input #dateOfBirth formControlName="dob" class=""></om-date-input>

            <small *ngIf="dateOfBirthError" class="d-block text-danger mt-2">
              Dependent must be {{ minimumAge }} years old to complete registration. You can register a child using our
              <a [routerLink]="['/registration/pediatric']">Kids Registration</a>
            </small>
          </om-label-wrapper>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <om-label-wrapper class="" errorMessage="You must choose an option." label="Sex" [hasError]="sexError">
            <om-toggle #sex [options]="sexOptions" formControlName="sex" classes="col-6 p-0 d-flex mb-1"></om-toggle>
          </om-label-wrapper>
          <small class="d-block text-black-50 mb-2">Select the option on file with your insurance</small>

          <a href *ngIf="!showGenderDetails" (click)="onShowGenderDetailsClick($event)" data-cy="showGenderDetails">
            Add gender information (optional)
          </a>
          <om-label-wrapper class="col-12 p-0 d-block" *ngIf="showGenderDetails" label="Gender Information (optional)">
            <textarea #genderDetails formControlName="genderDetails" data-cy="genderDetails"></textarea>
          </om-label-wrapper>
        </div>
      </div>

      <div class="row pt-1 mb-3">
        <div class="col-12">
          <om-checkbox
            class="mb-4"
            checkboxLabel="We share the same address"
            formControlName="sameAddress"
          ></om-checkbox>
        </div>
      </div>

      <div class="row" *ngIf="form.controls.address">
        <div class="col-12 mb-3">
          <om-address-input #address *ngIf="form.controls.address" [address]="form.controls.address"></om-address-input>
        </div>
      </div>

      <div class="row" *ngIf="directSignupError" class="d-block text-danger mt-2" data-cy="serverError">
        <div class="col-12 mb-3"> {{ directSignupError }} </div>
      </div>

      <div><om-submit-button class="col-12" label="Register" [submitting]="submitting"></om-submit-button></div>
      <div>
        <a class="btn btn-block btn-link" aria-label="cancel" (click)="cancelClicked()"> Cancel </a>
      </div>
    </form>
  </div>
</div>

<div *ngIf="createdPatient" class="container mb-5">
  <div class="row justify-content-center">
    <div class="col-md-8 pt-5 text-center">
      <img src="/assets/images/logo-confetti-transparent.svg" class="mb-4" height="150" />
      <h3 class="mb-4">Account created</h3>

      <p class="col-12 col-lg-8 offset-lg-2 lh-175">
        We've sent {{ createdPatient.preferredName || createdPatient.firstName }} an email at
        {{ createdPatient.email }} with instructions to finish setting up their account.
      </p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-8 text-center">
      <ng-content></ng-content>
    </div>
  </div>
</div>
