import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'om-checkbox[formControlName], om-checkbox[formControl]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxInputComponent),
    },
  ],
  templateUrl: '../checkbox/checkbox.component.html',
  styleUrls: ['../checkbox/checkbox.component.scss', '../form-input.scss'],
})
export class CheckboxInputComponent extends CheckboxComponent implements ControlValueAccessor {
  onChange: Function;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(isChecked: boolean): void {
    this.isChecked = isChecked;
  }

  handleCheck($event: Event | MouseEvent) {
    super.handleCheck($event);
    this.onChange(this.isChecked);
  }
}
