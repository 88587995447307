<h5 *ngIf="heading" class="mb-3 text-center">{{ heading }}</h5>
<!-- needs "row" class for list title (<dt>) and list description (<dd>) to be side-by-side, col-12 cancels out row negative margin -->
<dl class="row col-12 {{ listClass }}">
  <ng-container *ngFor="let item of listItems; last as isLast">
    <dt class="checkmark-wrapper">
      <om-svg-checkmark color="#004D49" width="16" height="13"></om-svg-checkmark>
    </dt>
    <dd [ngClass]="{ 'mb-3': !isLast }">{{ item }}</dd>
  </ng-container>
</dl>
