<div class="container mt-5 pt-5 d-flex">
  <div class="col-12 col-xl-5">
    <h2>Your child's membership is now active.</h2>
    <p class="lh-175">
      We sent a confirmation email to {{ patientEmail }} with details about your child’s account, including the login
      email address to use for virtual care services and managing appointments.
    </p>
    <button
      class="btn btn-primary btn-block mt-4"
      routerLink="/registration/pediatric"
      [omTrackLink]="trackAddAnotherChildClicked"
      data-cy="register"
    >
      Register another child
    </button>
    <div class="d-flex justify-content-center">
      <a [ngClass]="'btn btn-link'" (click)="goToNextStep()" data-cy="skip-button"> Skip for now </a>
    </div>
  </div>
  <div class="col-xl-7 d-none d-xl-flex justify-content-center">
    <img class="col-10" src="/assets/images/pediatrics.svg" role="presentation" />
  </div>
</div>
