import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { DefaultRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import mixpanel from 'mixpanel-browser';
import { RecaptchaModule } from 'ng-recaptcha';

import { AppStoreRedirectComponent } from '@app/app-store-redirect/app-store-redirect.component';
import { AuthEffects } from '@app/auth/auth.effects';
import { StoreDevModules } from '@app/build-specifics/devtool';
import { FeatureFlagEffects } from '@app/core/feature-flags/feature-flag.effects';
import { MixpanelService } from '@app/core/mixpanel.service';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from '@app/core/rollbar.service';
import { InjectorModule } from '@app/injector.module';
import { AppSyncNotificationService } from '@app/shared/app-sync-notification-service';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { MyOneApp } from './app.component';
import { metaReducers, reducers } from './app.reducer';
import { AppointmentBookingSessionService } from './appointment/appointment-booking-session.service';
import { AuthInterceptor } from './core/auth-interceptor';
import { CoreModule } from './core/core.module';
import { GraphQLModule } from './graphql.module';
import { SharedModule } from './shared/shared.module';

const initMixpanelService = (): MixpanelService => {
  mixpanel.init(environment.mixpanelToken, { cross_subdomain_cookie: environment.mixpanelCrossDomain });
  mixpanel.set_config({ debug: environment.mixpanelDebug });
  return new MixpanelService(mixpanel);
};

@NgModule({
  declarations: [MyOneApp, AppStoreRedirectComponent],
  imports: [
    InjectorModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    ScrollToModule.forRoot(),
    RecaptchaModule,
    GraphQLModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId,
    }),
    EffectsModule.forRoot([AuthEffects, FeatureFlagEffects]),
    EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    StoreDevModules,
  ],
  providers: [
    AppointmentBookingSessionService,
    AppSyncNotificationService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MixpanelService, useFactory: initMixpanelService },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
  ],
  bootstrap: [MyOneApp],
})
export class MyOneAppModule {}
