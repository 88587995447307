import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

import { OmguiCameraModule } from '@omgui/omgui-camera/omgui-camera.component';
import { OmguiSpinnerModule } from '@omgui/omgui-spinner/omgui-spinner.component';

import { OmguiProfileBubbleComponent } from './omgui-profile-bubble.component';

@Component({
  selector: 'omgui-provider-profile-bubble',
  templateUrl: 'omgui-profile-bubble.component.html',
  styleUrls: ['omgui-profile-bubble.component.scss'],
})
export class OmguiProviderProfileBubbleComponent extends OmguiProfileBubbleComponent {
  @Input() set colorCombo(_: number) {}

  selectedColorCombo = OmguiProfileBubbleComponent.PROVIDER_COLOR_COMBINATION;
}

@NgModule({
  declarations: [OmguiProviderProfileBubbleComponent],
  imports: [CommonModule, OmguiCameraModule, OmguiSpinnerModule],
  exports: [OmguiProviderProfileBubbleComponent],
})
export class OmguiProviderProfileBubbleModule {}
