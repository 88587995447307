<form [formGroup]="dateForm" novalidate class="row">
  <div class="col-3 pr-0">
    <input
      #monthInput
      type="text"
      class="form-control"
      id="month"
      data-cy="month"
      placeholder="MM"
      formControlName="month"
      [maxlength]="monthMaxLength"
      aria-label="Month of date of birth"
      [readonly]="readOnly"
    />
  </div>
  <div class="col-3 pr-0">
    <input
      #dayInput
      type="text"
      class="form-control"
      id="day"
      data-cy="day"
      placeholder="DD"
      formControlName="day"
      [maxlength]="dayMaxLength"
      aria-label="Date of date of birth"
      [readonly]="readOnly"
    />
  </div>
  <div class="col">
    <input
      #yearInput
      type="text"
      class="form-control"
      id="year"
      data-cy="year"
      placeholder="YYYY"
      formControlName="year"
      maxlength="4"
      aria-label="Year of date of birth"
      [readonly]="readOnly"
    />
  </div>
</form>
