import { Injectable } from '@angular/core';
import { Query, gql, Apollo } from 'apollo-angular';

import { B2bCompany, B2bCompanyVariables } from './__generated__/B2bCompany';

@Injectable()
export class B2bCompanyGraphQL extends Query<B2bCompany, B2bCompanyVariables> {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  document = gql`
    query B2bCompany($b2bEmail: String, $reCaptchaToken: String) {
      b2bCompany(b2bEmail: $b2bEmail, reCaptchaToken: $reCaptchaToken) {
        id
        b2bEmailDomains
        includesDependent
        verifyDependentInfo
        activationCodeRetrievableViaEmail
        displayElistError
        customElistError
      }
    }
  `;
}
