import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { LinksService } from '@app/core/links.service';
import { EmailInputComponent, PasswordInputComponent } from '@app/shared';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-create-account-step',
  styleUrls: ['../../../shared/form-input.scss'],
  templateUrl: './create-account-step.component.html',
})
export class CreateAccountStepComponent extends RegistrationStepDirective {
  @Input() emailError: string;
  @Input() passwordError: string;
  @ViewChild('logInEmail', { static: true }) logInEmail: EmailInputComponent;
  @ViewChild('password', { static: true }) password: PasswordInputComponent;

  constructor(private links: LinksService) {
    super();
  }

  onSubmit() {
    this.submitting = true;
    this.logInEmail.markAsTouchedAndDirty();
    this.password.markAsTouchedAndDirty();
    this.submit.emit();
  }

  onError() {
    this.submitting = false;
  }

  get combinedEmailError() {
    return `${this.emailError} If you've lost access to your account <a href="${this.links.onelife.resetPassword}">reset your password</a>, or email <a href="${this.links.adminEmail}">admin@onemedical.com</a>.`;
  }
}
