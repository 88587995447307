import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, shareReplay, takeUntil } from 'rxjs/operators';

import { WindowService } from '@app/utils/window.service';

import { ApiHeaderService } from './api-header.service';
import { AttemptedPathService } from './attempted-path.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private apiHeaderService: ApiHeaderService,
    private config: ConfigService,
    private authService: AuthService,
    private attemptedPathService: AttemptedPathService,
    private windowService: WindowService,
  ) {}

  private destroy$: Subject<void> = new Subject<void>();

  get<T>(path: string, ignoreUnauthorized = false, body: Record<string, any> = {}) {
    return this.http
      .get<T>(`${this.config.json.apiServer}${path}`, {
        headers: this.apiHeaderService.headers,
        params: body,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  post<T>(path: string, body: Record<string, any>, ignoreUnauthorized = false) {
    return this.http
      .post<T>(`${this.config.json.apiServer}${path}`, body, {
        headers: this.apiHeaderService.headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  patch(path: string, body: Record<string, any>, ignoreUnauthorized = false) {
    return this.http
      .patch(`${this.config.json.apiServer}${path}`, body, {
        headers: this.apiHeaderService.headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  put(path: string, body: Record<string, any>, ignoreUnauthorized = false) {
    return this.http
      .put(`${this.config.json.apiServer}${path}`, body, {
        headers: this.apiHeaderService.headers,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  delete(path: string, body: Record<string, any> = {}, ignoreUnauthorized = false) {
    return this.http
      .delete(`${this.config.json.apiServer}${path}`, {
        headers: this.apiHeaderService.headers,
        params: body,
        withCredentials: true,
      })
      .pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
        takeUntil(this.destroy$),
        catchError((error, caught) => this.handleHttpError(error, ignoreUnauthorized)),
      );
  }

  unsubscribe(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleHttpError(error: any, ignoreUnauthorized: boolean) {
    if (error.status === 401 && !ignoreUnauthorized) {
      this.attemptedPathService.setAttemptedPath(this.windowService.getLocationPathname());
      this.unsubscribe();
      this.authService.logout(this.windowService.getLocationPathname());
    }
    return observableThrowError(error);
  }
}
