import { Component, HostBinding, Input } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';

const REACTIVE_LABEL_WIDTH_CLASSES = 'mt-5 col-9 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 text-center';

@Component({
  selector: 'om-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButton {
  @Input() submitting: boolean;
  @Input() label: string;
  @Input() buttonClass: string;
  @Input() disabled = false;
  @Input() @HostBinding('class') class: string = REACTIVE_LABEL_WIDTH_CLASSES;

  readonly componentSize = ComponentSize;
}
