<omgui-feature-card>
  <omgui-feature-card-content
    [title]="data.title"
    [imageUrl]="data.imageUrl"
    [imageAltText]="data.imageAltText"
    [description]="data.description"
  >
    <ng-template omgui-feature-card-primary-action>
      <a
        *ngIf="data.primaryActionLabel"
        [href]="data.primaryActionUrl"
        class="btn btn-primary px-3 mr-3 mr-sm-0"
        target="_blank"
        rel="noopener noreferrer"
        [omTrackLink]="trackPrimaryLinkClicked()"
        data-cy="card-action-primary"
        >{{ data.primaryActionLabel }}<omgui-external-link-icon class="d-inline-flex ml-2"></omgui-external-link-icon>
      </a>
    </ng-template>
    <ng-template omgui-feature-card-secondary-action>
      <button
        *ngIf="data.secondaryActionLabel"
        class="btn btn-link text-primary"
        (click)="secondaryAction.emit($event)"
        data-cy="card-action-secondary"
        >{{ data.secondaryActionLabel }}</button
      >
    </ng-template>
  </omgui-feature-card-content>
</omgui-feature-card>
