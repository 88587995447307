import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProgramHubAnalyticsService } from '../program-hub/program-hub-analytics.service';
import { HubCareOptionItem } from '../program-types';

type ProgramFeatureCardVariant = 'primary' | 'secondary';

@Component({
  selector: 'om-program-feature-card',
  templateUrl: './program-feature-card.component.html',
})
export class ProgramFeatureCardComponent {
  @Output() secondaryAction = new EventEmitter<void>();
  @Input() data: HubCareOptionItem;

  constructor(private programHubAnalyticsService: ProgramHubAnalyticsService) {}

  trackPrimaryLinkClicked() {
    return this.programHubAnalyticsService.trackProgramModuleClicked.bind(this.programHubAnalyticsService, {
      module: this.data?.moduleName,
      ctaType: 'primary',
    });
  }
}
