import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { UserService } from '@app/core/user.service';
import { HomePageAnalyticsService } from '@app/home/home-page-analytics.service';
import { RemoveCardEvent } from '@app/shared';

export const MENTAL_HEALTH_VISIT_APPT_TYPE_ID = '303';

@Component({
  selector: 'om-mental-health-pcp-visit-card',
  templateUrl: './mental-health-pcp-visit-card.component.html',
})
export class MentalHealthPcpVisitCardComponent implements OnInit {
  static StorageKey = 'hide_pcp_mh_visit_card';
  static appointmentTypeName = 'Mental Health Visit';
  MENTAL_HEALTH_VISIT_APPT_TYPE_URL = `appointments/get-care`;
  MENTAL_HEALTH_VISIT_QUERY_PARAMS = { appointmentTypeId: MENTAL_HEALTH_VISIT_APPT_TYPE_ID };

  @Output() removeCard = new EventEmitter<RemoveCardEvent>();

  canDismiss: boolean;
  appointmentTypeId: string;

  constructor(
    private homePageAnalyticsService: HomePageAnalyticsService,
    private userService: UserService,
    private router: Router,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit(): void {
    this.homePageAnalyticsService.trackMentalHealthPromotionCardDisplayed();
    this.userService
      .getUser()
      .pipe(take(1))
      .subscribe(user => {
        this.canDismiss = !user.isVirtual;
      });
    this.userService.getUser();
  }

  dismissCard(event: MouseEvent) {
    event.preventDefault();
    this.homePageAnalyticsService.trackMentalHealthPromotionCardClicked({ clickType: 'Dismiss' });

    this.removeCard.emit({
      component: MentalHealthPcpVisitCardComponent,
      storageKey: MentalHealthPcpVisitCardComponent.StorageKey,
    });
  }

  handleClickImage() {
    this.homePageAnalyticsService.trackMentalHealthPromotionCardClicked({ clickType: 'Image' });

    this.router.navigate([`/appointments/get-care`], {
      queryParams: { appointmentTypeId: MENTAL_HEALTH_VISIT_APPT_TYPE_ID },
    });
  }

  trackMentalHealthPromoCardClicked(clickType: string): () => Observable<any> {
    return () =>
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.BHX_PCP_MENTAL_HEALTH_VISIT, false).pipe(
        switchMap((experimentVariation: boolean) =>
          this.homePageAnalyticsService.trackMentalHealthPromotionCardClicked({
            clickType,
            experimentName: FeatureFlags.BHX_PCP_MENTAL_HEALTH_VISIT,
            experimentVariation,
          }),
        ),
      );
  }
}
