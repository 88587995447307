import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OmguiActionModule } from './omgui-action/omgui-action.component';
import { OmguiBannerModule } from './omgui-banner/omgui-banner.component';
import { OmguiCameraModule } from './omgui-camera/omgui-camera.component';
import { OmguiCloseXModule } from './omgui-close-x/omgui-close-x.component';
import { OmguiCollapsibleListCardModule } from './omgui-collapsible-list-card/omgui-collapsible-list-card.component';
import { OmguiCompletionAccordionModule } from './omgui-completion-accordion/omgui-completion-accordion.component';
import { OmguiDisplayCardModule } from './omgui-display-card/omgui-display-card.component';
import { OmguiExternalLinkIconModule } from './omgui-external-link-icon/omgui-external-link-icon.component';
import { OmguiFeatureCardModule } from './omgui-feature-card/omgui-feature-card.component';
import { OmguiListCardModule } from './omgui-list-card/omgui-list-card.component';
import { OmguiMultipleAvatarDisplayModule } from './omgui-multiple-avatar-display/omgui-multiple-avatar-display.component';
import { OmguiProfileBubbleModule } from './omgui-profile-bubble/omgui-profile-bubble.component';
import { OmguiProviderProfileBubbleModule } from './omgui-profile-bubble/omgui-provider-profile-bubble.component';
import { OmguiProviderNotificationAvatarModule } from './omgui-provider-notification-avatar/omgui-provider-notification-avatar.component';
import { OmguiSpinnerModule } from './omgui-spinner/omgui-spinner.component';
import { OmguiStandardCardModule } from './omgui-standard-card/omgui-standard-card.component';
import { OmguiUpwardTrendIconModule } from './omgui-upward-trend-icon/omgui-upward-trend-icon.component';

const modules = [
  OmguiActionModule,
  OmguiBannerModule,
  OmguiCameraModule,
  OmguiCloseXModule,
  OmguiCollapsibleListCardModule,
  OmguiCompletionAccordionModule,
  OmguiDisplayCardModule,
  OmguiExternalLinkIconModule,
  OmguiFeatureCardModule,
  OmguiListCardModule,
  OmguiMultipleAvatarDisplayModule,
  OmguiProfileBubbleModule,
  OmguiProviderNotificationAvatarModule,
  OmguiProviderProfileBubbleModule,
  OmguiSpinnerModule,
  OmguiStandardCardModule,
  OmguiUpwardTrendIconModule,
];

@NgModule({
  imports: [CommonModule, ...modules],
  exports: modules,
})
export class OmguiModule {}
