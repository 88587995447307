import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';

import { Address, BasicAddress } from '@app/shared/address';
import { AddressDisplayStyle } from '@app/shared/address-display/address-display.component';
import { DateInputComponent } from '@app/shared/date-input/date-input.component';
import { PhoneNumberInputComponent } from '@app/shared/phone-number-input/phone-number-input.component';

import { HealthInformationReleaseService } from './health-information-release.service';
@Component({
  selector: 'om-health-information-release-form',
  styleUrls: ['../form-input.scss'],
  templateUrl: './health-information-release-form.component.html',
})
export class HealthInformationReleaseFormComponent implements OnInit {
  @ViewChild('recipientSection', { static: true }) recipientSection;
  @ViewChild('recipientTelephone', { static: true }) recipientTelephone: PhoneNumberInputComponent;
  @ViewChild('releaseInformation', { static: true }) releaseInformation;
  @ViewChild('signedAt', { static: true }) signedAt: DateInputComponent;
  @ViewChild('top', { static: true }) top;

  @Input() description: String = '';
  @Output() hirAuthorizationSuccess = new EventEmitter();

  inputDisplayStyle = AddressDisplayStyle.INPUT;
  submitting = false;
  submissionError = false;
  formBuilder = new FormBuilder();
  form = this.formBuilder.group({
    consenterFirstName: ['', Validators.required],
    consenterLastName: ['', Validators.required],
    consenterDateOfBirth: { date: '' },
    consenterAddress: this.formBuilder.group(Address.buildBasicAddressControlsConfig()),
    recipientName: ['', Validators.required],
    recipientTelephone: ['', Validators.required],
    expiresAt: { date: '' },
    signatureText: ['', Validators.required],
    signedAt: { date: '' },
  });

  constructor(public releaseService: HealthInformationReleaseService) {}

  ngOnInit() {
    this.releaseService.getUserAndMembership().subscribe(([user, membership]) => {
      const { firstName, lastName, dob, address } = user;
      const { address1, address2, city, state, zip } = address;
      const basicAddress: BasicAddress = { address1, address2, city, state, zip };
      let recipientName = '';
      if (membership.b2bCompany) {
        recipientName = membership.b2bCompany.displayName;
      }
      this.updateForm(basicAddress, firstName, lastName, dob, recipientName);
    });
  }

  onSubmit() {
    this.submitting = true;
    this.submissionError = false;
    this.markAsTouchedAndDirty();
    if (!this.form.valid) {
      this.submitting = false;
      this.scrollToFirstInvalid();
      return;
    }
    this.releaseService.createAuthorization(this.form).subscribe(
      () => {
        this.hirAuthorizationSuccess.emit();
      },
      () => {
        this.submitting = false;
        this.submissionError = true;
      },
    );
  }

  allRightsChecked() {
    return this.rightsControls().every(control => control.value);
  }

  releasedInformation() {
    const { releaseCovidInformation } = this.form.controls;
    return !releaseCovidInformation.value;
  }

  showReleasedInformationError() {
    const { releaseCovidInformation } = this.form.controls;
    return !releaseCovidInformation.value && releaseCovidInformation.dirty;
  }

  showMyRightsError() {
    return this.rightsControls().some(control => control.touched && !control.value);
  }

  isMinor(): boolean {
    if (!this.form.value.consenterDateOfBirth.date) {
      return false;
    }
    return moment().diff(moment(this.form.value.consenterDateOfBirth.date), 'years') < 18;
  }

  get signatureLabel() {
    if (this.isMinor()) {
      return 'Signature of Parent or Legal Guardian';
    }
    return 'Signature of Patient';
  }

  private updateForm(consenterAddress, consenterFirstName, consenterLastName, consenterDob, recipientName) {
    const { address1, address2, city, state, zip } = consenterAddress;
    this.form.patchValue({
      consenterFirstName: consenterFirstName,
      consenterLastName: consenterLastName,
      consenterDateOfBirth: { date: consenterDob },
      consenterAddress: { address1, address2, city, state, zip },
      recipientName: recipientName,
    });
  }

  private markAsTouchedAndDirty() {
    this.recipientTelephone.markAsTouchedAndDirty();
    this.signedAt.markAsTouchedAndDirty();
    this.form.markAllAsTouched();
    for (const [, control] of Object.entries(this.form.controls)) {
      control.markAsDirty();
      control.markAsTouched();
    }
  }

  private scrollToFirstInvalid() {
    const {
      consenterFirstName,
      consenterLastName,
      consenterDateOfBirth,
      consenterAddress,
      recipientName,
      recipientTelephone,
    } = this.form.controls;

    if (
      consenterFirstName.invalid ||
      consenterLastName.invalid ||
      consenterDateOfBirth.invalid ||
      consenterAddress.invalid
    ) {
      this.top.nativeElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (recipientName.invalid || recipientTelephone.invalid) {
      this.recipientSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (this.releasedInformation()) {
      this.releaseInformation.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (!this.allRightsChecked()) {
      this.releaseInformation.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  private rightsControls() {
    const {
      voluntaryAuthorizationAgreement,
      authorizationRevocationAgreement,
      privacyProtectionReleaseAgreement,
      oneMedicalLiabilityReleaseAgreement,
      copyEntitlementAgreement,
      copyUnderstandingOfTreatment,
    } = this.form.controls;
    return [
      voluntaryAuthorizationAgreement,
      authorizationRevocationAgreement,
      privacyProtectionReleaseAgreement,
      oneMedicalLiabilityReleaseAgreement,
      copyEntitlementAgreement,
      copyUnderstandingOfTreatment,
    ];
  }
}
