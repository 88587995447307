<div>
  <button
    class="btn btn-block btn-primary {{ buttonClass }}"
    [disabled]="disabled"
    type="submit"
    aria-label="submit"
    data-cy="submit"
    [hidden]="submitting"
  >
    {{ label }}
  </button>
  <omgui-spinner
    class="d-block text-center {{ buttonClass }}"
    [hidden]="!submitting"
    [size]="componentSize.extraSmall"
  ></omgui-spinner>
</div>
